import { Money } from "@mui/icons-material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoNotDisturbOffOutlinedIcon from "@mui/icons-material/DoNotDisturbOffOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { addDays, format, isBefore, setDate } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContent } from "../../../shared/components";
import { useToast } from "../../../shared/hooks";
import { FinancialService } from "../../../shared/services/api/financial/FinancialService";
import { copyToClipboard, globalMaskDocument, globalTranslate } from "../../../shared/utils";
import { IClientInvoices } from "./FinancialHealthListPresentation";
import { ModalConfirmBillet } from "./ModalCofirmBillet";
import { TableFinancialDetail } from "./TableFinancialDetail";

export const CustomizedAccordions: React.FC<IClientInvoices> = ({
	id,
	name,
	document,
	invoice,
	invoice_due_date,
	statusClient,
	openSum,
	pendingSum,
	openCount,
	pendingCount,
	paidSum,
	paidCount,
	overdueSum,
	overdueCount,
	email,
	nameLoading = false,
	listInvoiceSelect,
	overRefresh,
}) => {
	const toastHandler = useToast();
	const [isLoading, setIsLoading] = useState(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [checkboxAllSelected, setCheckboxAllSelected] = useState(true);
	const navigate = useNavigate();
	const [isRefresh, setIsRefresh] = useState(false);
	const [modalInvoice, setModalInvoice] = useState(false);
	const [dueDate, setDueDate] = useState("");

	const handleDueDate = (value: string) => {
		const newValue = format(new Date(value), "yyyy-MM-dd HH:mm:ss");
		setDueDate(newValue);
	};

	useEffect(() => {
		if (modalInvoice) {
			setDueDate(calcularVencimento(invoice_due_date ? parseInt(invoice_due_date) : 1));
		}
	}, [modalInvoice]);

	const calcularVencimento = (diaVencimento: number): string => {
		const hoje = new Date();
		let dataVencimento = setDate(new Date(), diaVencimento);
		if (isBefore(dataVencimento, hoje)) {
			dataVencimento = addDays(hoje, 1);
		}
		return format(dataVencimento, "yyyy-MM-dd hh:mm:ss");
	};

	const handleSelectInvoice = (clientId: string, invoiceId: string, selected: boolean) => {
		// Verifica se já existe um elemento com clientId e invoiceId iguais
		const existItem = listInvoiceSelect.find((item) => item.clientId === clientId && item.invoiceId === invoiceId);
		if (existItem) {
			// Se já existe, atualize o valor de 'selected'
			existItem.selected = selected;
		} else {
			// Se não existe, adicione um novo objeto ao array
			listInvoiceSelect.push({ clientId, invoiceId, selected });
		}
	};

	const handleCheckboxChange = (checked: boolean) => {
		setCheckboxAllSelected(checked);
	};

	const handleRefresh = (value: boolean) => {
		setIsRefresh(value);
		overRefresh(value);
	};

	const handleGenerateInvoice = (id: string, dueDate: string) => {
		setIsLoading(true);
		const existItem = listInvoiceSelect.find((item) => item.clientId === id && item.selected === true);
		const exist = listInvoiceSelect.filter((item) => item.clientId === id && item.selected === true);
		if (exist.length == 0) {
			setIsLoading(false);
			toastHandler.present({
				type: "warning",
				position: "top-right",
				messageOrContent: <ToastContent title={"Alerta"} content={"não existe registro selecionado"} />,
			});
		} else if (exist.length == 1) {
			FinancialService.generateBillet(exist[0].invoiceId, dueDate).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					alert(result.message);
				} else {
					toastHandler.present({
						type: "success",
						position: "top-right",
						messageOrContent: <ToastContent title={"Sucesso"} content={"Boleto enviado com sucesso"} />,
					});
					//setRefreshList(true);
					setIsRefresh(true);
					overRefresh(true);
					setIsButtonDisabled(false);
				}
			});
		} else {
			const onlyInvoiceId = listInvoiceSelect.filter((item) => item.clientId === id && item.selected === true).map((item) => item.invoiceId);
			setIsLoading(false);
			FinancialService.generateGroupedBillet(id, dueDate, onlyInvoiceId).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={"Erro"} content={result.message} />,
					});
				} else {
					toastHandler.present({
						type: "success",
						position: "top-right",
						messageOrContent: <ToastContent title={"Sucesso"} content={"Boleto enviado com sucesso"} />,
					});
					//setRefreshList(true);
					setIsRefresh(true);
					overRefresh(true);
					setIsButtonDisabled(false);
				}
			});
		}
	};

	useEffect(() => {
		listInvoiceSelect = [];
		if (checkboxAllSelected) {
			invoice.forEach((item) => {
				const checkStatus = item.status && !["paid", "canceled"].includes(item.status) ? true : false;
				listInvoiceSelect.push({
					clientId: id,
					invoiceId: item.id,
					selected: checkStatus,
				});
			});
		} else {
			listInvoiceSelect = [];
		}
	}, [checkboxAllSelected]);

	return (
		<div>
			<Accordion sx={{ border: "1px solid #f1f1f2", margin: 0, width: "auto" }} defaultExpanded={false}>
				<AccordionSummary
					expandIcon={<KeyboardArrowDownIcon />}
					onClick={(event) => event.stopPropagation()} // Prevenindo a expansão/collapse ao clicar no AccordionSummary
					onFocus={(event) => event.stopPropagation()}
				>
					<FormControlLabel
						aria-label="Acknowledge"
						onClick={(event) => event.stopPropagation()}
						onFocus={(event) => event.stopPropagation()}
						control={<Box sx={{ marginRight: 2 }} />}
						label={
							<Box sx={{ width: "150vh" }}>
								{!nameLoading && (
									<Box sx={{ width: "98%", margin: 0, border: "none", padding: 0 }}>
										<Table sx={{ margin: 0, border: "none", padding: 0 }}>
											<TableCell width={"44%"} sx={{ margin: 0, border: "none", padding: 0 }}>
												<Box display={"flex"} alignItems={"center"} sx={{ gap: 1 }}>
													<Typography
														sx={{
															fontWeight: "700",
															color: statusClient === "active" ? "#837f7f" : "red",
														}}
													>
														{statusClient != "active" ? name + " (" + globalTranslate(statusClient) + ") " : name}
													</Typography>
													<IconButton
														aria-label="copy"
														size="small"
														onClick={() => {
															copyToClipboard(name);
														}}
													>
														<ContentCopyIcon fontSize="inherit" sx={{ color: "#b1b1b1" }} />
													</IconButton>
												</Box>
											</TableCell>

											<TableCell width={"14%"} sx={{ margin: 0, border: "none", padding: 0 }}>
												<Box display={"flex"} alignItems={"center"} sx={{ gap: 1 }}>
													<Typography
														variant="h6"
														fontWeight={"400"}
														sx={{
															color: overdueCount != "0" ? "red" : "#959595",
														}}
													>
														{parseFloat(overdueSum || "0.00").toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
													</Typography>
												</Box>
											</TableCell>

											<TableCell width={"14%"} sx={{ margin: 0, border: "none", padding: 0 }}>
												<Box display={"flex"} alignItems={"center"} sx={{ gap: 1 }}>
													<Typography
														variant="h6"
														fontWeight={"400"}
														sx={{
															color: openCount != "0" ? "#ffd119" : "#959595",
														}}
													>
														{parseFloat(openSum || "0.00").toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
													</Typography>
												</Box>
											</TableCell>

											<TableCell width={"14%"} sx={{ margin: 0, border: "none", padding: 0 }}>
												<Box display={"flex"} alignItems={"center"} sx={{ gap: 1 }}>
													<Typography
														variant="h6"
														fontWeight={"400"}
														sx={{
															color: pendingCount != "0" ? "#0099ff" : "#959595",
														}}
													>
														{parseFloat(pendingSum || "0.00").toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
													</Typography>
												</Box>
											</TableCell>

											<TableCell width={"14%"} sx={{ margin: 0, border: "none", padding: 0 }}>
												<Box display={"flex"} alignItems={"center"} sx={{ gap: 1 }}>
													<Typography
														variant="h6"
														color={"#959595"}
														fontWeight={"400"}
														sx={{
															color: paidCount != "0" ? "#03e900" : "#959595",
														}}
													>
														{parseFloat(paidSum || "0.00").toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
													</Typography>
												</Box>
											</TableCell>
										</Table>

										<Table sx={{ margin: 0, border: "none", padding: 0 }}>
											<TableCell width={"44%"} sx={{ margin: 0, border: "none", padding: 0 }}>
												<Box display={"flex"} alignItems={"center"} sx={{ gap: 1 }}>
													<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
														{globalMaskDocument(document)}
													</Typography>
													<IconButton
														aria-label="copy"
														size="small"
														onClick={() => {
															copyToClipboard(document);
														}}
													>
														<ContentCopyIcon fontSize="inherit" sx={{ color: "#b1b1b1" }} />
													</IconButton>
												</Box>
												{statusClient != "blocked" ? (
													<Tooltip title="Bloquear Imobiliária">
														<IconButton
															aria-label="copy"
															size="small"
															onClick={() => {
																navigate(`/bloqueio/cliente/${id}`);
															}}
															sx={{ gap: 1, color: "red" }}
														>
															<Typography variant="subtitle2">Bloquear</Typography>
															<DoNotDisturbOffOutlinedIcon fontSize="inherit" sx={{ color: "red" }} />
														</IconButton>
													</Tooltip>
												) : (
													""
												)}
											</TableCell>
											<TableCell width={"14%"} sx={{ margin: 0, border: "none", padding: 0 }}>
												<Box display={"flex"} alignItems={"center"} sx={{ gap: 1 }}>
													<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
														Vencidos ( {overdueCount} )
													</Typography>
												</Box>
											</TableCell>
											<TableCell width={"14%"} sx={{ margin: 0, border: "none", padding: 0 }}>
												<Box display={"flex"} alignItems={"center"} sx={{ gap: 1 }}>
													<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
														A vencer ( {openCount} )
													</Typography>
												</Box>
											</TableCell>
											<TableCell width={"14%"} sx={{ margin: 0, border: "none", padding: 0 }}>
												<Box display={"flex"} alignItems={"center"} sx={{ gap: 1 }} textAlign={"center"}>
													<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
														Faturados ( {pendingCount} )
													</Typography>
												</Box>
											</TableCell>
											<TableCell width={"14%"} sx={{ margin: 0, border: "none", padding: 0 }}>
												<Box display={"flex"} alignItems={"center"} sx={{ gap: 1 }} textAlign={"center"}>
													<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
														Pagos ( {paidCount} )
													</Typography>
												</Box>
											</TableCell>
										</Table>
									</Box>
								)}
								{nameLoading && <Skeleton height={"50px"} width={"60rem"} />}
							</Box>
						}
					/>
				</AccordionSummary>

				<AccordionDetails>
					{!nameLoading && (
						<Box sx={{ margin: 0 }}>
							<Table size="small" aria-label="purchases" sx={{ background: "#fcf8ff" }}>
								<TableHead>
									<TableRow sx={{ background: "#dbdbdb" }}>
										<TableCell>
											<Checkbox
												color="primary"
												checked={checkboxAllSelected}
												onChange={(event) => {
													handleCheckboxChange(event.target.checked);
												}}
												inputProps={{ "aria-label": "select all desserts" }}
											/>
										</TableCell>
										<TableCell>Faturamento</TableCell>
										<TableCell>Vencimento</TableCell>
										<TableCell>Valor</TableCell>
										<TableCell>Parcelas</TableCell>
										<TableCell>Status</TableCell>
										<TableCell>
											<Box display={"flex"} alignItems={"center"} sx={{ gap: 1 }}>
												<Tooltip title="Gerar Boletox">
													<Button
														variant="contained"
														startIcon={<Money />}
														disabled={isButtonDisabled}
														onClick={() => {
															setIsButtonDisabled(true);
															setModalInvoice(true);
															//handleGenerateInvoice(id)
														}}
													>
														Boleto
													</Button>
												</Tooltip>
											</Box>
										</TableCell>
										<TableCell>#</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{invoice?.map((rowDetail, index) => (
										<TableFinancialDetail
											dueDate={rowDetail.dueDate}
											clientEmail={email}
											id={rowDetail.id}
											clientId={rowDetail.clientId}
											status={rowDetail.status}
											gateway={rowDetail.gateway}
											installmentsNumber={rowDetail.installmentsNumber}
											reference={rowDetail.reference}
											value={rowDetail.value}
											paymentDate={rowDetail.paymentDate ? rowDetail.paymentDate.substring(0, 10) + " 03:00:01" : ""}
											onSelectCheckBox={handleSelectInvoice}
											isChecked={checkboxAllSelected}
											groupedNumber={rowDetail.groupedNumber}
											clientName={name}
											isRefresh={handleRefresh}
											rowDetail={rowDetail}
										/>
									))}
								</TableBody>
							</Table>
						</Box>
					)}
					{nameLoading && <Skeleton height={"50px"} width={"30rem"} />}
				</AccordionDetails>
			</Accordion>

			{modalInvoice && (
				<ModalConfirmBillet
					clientName={name}
					dueDate={dueDate}
					id="111"
					isOpen={modalInvoice}
					onClose={() => {
						setModalInvoice(false);
						setIsButtonDisabled(false);
					}}
					onchangeDueDate={(value) => handleDueDate(value)}
					onConfirm={() => {
						handleGenerateInvoice(id, dueDate);
						setModalInvoice(false);
						setIsButtonDisabled(false);
					}}
				/>
			)}
		</div>
	);
};
