import { DoneOutline, Rule } from "@mui/icons-material";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { Box, Button, Grid, Paper, Skeleton, Typography } from "@mui/material";
import { format, isValid } from "date-fns";
import React from "react";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { useToast } from "../../../../../shared/hooks";
import { ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";
import { ModalConcludeCancelProposal } from "../Cancel/ModalConcludeCancel";
import { ModalPendencyCancelProposal } from "../Cancel/ModalPendencyCancel";
import ModalWaiverCancel from "../Cancel/ModalWaiverCancel";

interface ProposalCanceledCardProps {
	rows: any;
	smDown: boolean;
	permissionLevel: number;
	isLoading: boolean;
}

const ProposalCanceledCard: React.FC<ProposalCanceledCardProps> = ({ rows, smDown, permissionLevel, isLoading }) => {
	const backgroundStyle = { backgroundColor: "#f0f1f1" };
	const [openModalCancelConclude, setOpenModalCancelConclude] = React.useState(false);
	const [openModalCancelWaiver, setOpenModalCancelWaiver] = React.useState(false);
	const [openModalCancelPendency, setOpenModalCancelPendency] = React.useState(false);
	const [openModalRemovePendency, setOpenModalRemovePendency] = React.useState(false);
	const toastHandler = useToast();

	// Função para formatar datas com verificação
	const formatDate = (date?: Date | string) => {
		if (!date) return "N/A";
		const validDate = new Date(date);
		return isValid(validDate) ? format(validDate, "dd/MM/yyyy") : "Data inválida";
	};

	// Validação de rows e status
	if (!rows || (rows.status !== "suspended" && rows.status !== "canceled")) {
		return null;
	}

	const handleRemoveProposalPendency = async () => {
		try {
			const result = await ProposalService.updatePendencyCancelId(rows.id, { substatus: "notpending", substatusreason: "" });

			if (result instanceof Error) {
				throw new Error(result.message);
			}

			toastHandler.present({
				type: "success",
				position: "top-right",
				messageOrContent: <ToastContent title="Sucesso" content="Cancelamento efetivado com sucesso" />,
			});
			window.location.reload();
		} catch (error: any) {
			console.error("Erro ao remover pendência:", error);
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title="Erro" content="Falha ao remover a pendência. Tente novamente." />,
			});
		} finally {
			setOpenModalRemovePendency(false);
		}
	};

	const pendencyActions = (
		<Box display="flex" alignItems="center" gap={2}>
			<Button color="inherit" variant="contained" onClick={() => setOpenModalRemovePendency(false)}>
				Cancelar
			</Button>
			<Button color="primary" variant="contained" onClick={handleRemoveProposalPendency}>
				Remover Pendência
			</Button>
		</Box>
	);

	return (
		<>
			<Grid item xs={12} md={12}>
				<Grid container sm={12} display="flex" flexDirection={smDown ? "column" : "row"} component={Paper} padding={2} ml={1}>
					<Grid container item direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
						<Typography variant="h6" color="red">
							{isLoading ? <Skeleton width={200} /> : "Cancelamento de Garantia"}
						</Typography>
						{rows.substatus === "pending" && (
							<Typography variant="body1" color="red">
								- Cancelamento Pendente: {rows.substatusReason}
							</Typography>
						)}
					</Grid>

					<Grid container item direction="row" padding={1} spacing={1}>
						{[
							{
								label: "Solicitação do Cancelamento",
								value: rows?.canceledOpen ? formatDate(rows.canceledOpen.substring(0, 10) + " 03:00:00") : "",
							},
							{ label: "Cancelamento do Contrato", value: rows?.canceledAt ? formatDate(rows.canceledAt.substring(0, 10) + " 03:00:00") : "N/A" },
							{
								label: "Efetivação do Cancelamento",
								value: rows?.canceledConclude ? formatDate(rows.canceledConclude.substring(0, 10) + " 03:00:00") : "",
							},
							{ label: "Motivo", value: rows?.canceledType || "" }, // Não formata, apenas exibe o texto
							{ label: "Descrição", value: rows?.canceledReason || "" }, // Não formata, apenas exibe o texto
						].map(({ label, value }, index) => (
							<Grid key={index} item xs={12} sm={12} md={index < 4 ? 2 : 4} sx={backgroundStyle}>
								<Typography variant="caption" color="text.secondary">
									{label}
								</Typography>
								<Typography variant="subtitle2">{isLoading ? <Skeleton width={200} /> : value}</Typography>
							</Grid>
						))}

						<Box sx={{ display: "flex", justifyContent: "center", gap: 2, mt: 2 }}>
							{rows?.status === "suspended" && (
								<>
									<Button
										color="primary"
										disableElevation
										variant="outlined"
										startIcon={<PowerSettingsNewOutlinedIcon />}
										onClick={() => setOpenModalCancelWaiver(true)}
										disabled={isLoading}
									>
										Desistir do Cancelamento
									</Button>

									<Button
										color="primary"
										disableElevation
										variant="outlined"
										onClick={() => {
											rows.substatus !== "pending" ? setOpenModalCancelPendency(true) : setOpenModalRemovePendency(true);
										}}
										startIcon={<Rule />}
										disabled={isLoading}
									>
										{rows.substatus !== "pending" ? "Pendenciar Cancelamento" : "Remover Pendência"}
									</Button>

									{permissionLevel === 1 && rows.substatus !== "pending" && (
										<Button
											color="primary"
											disableElevation
											variant="contained"
											onClick={() => setOpenModalCancelConclude(true)}
											startIcon={<DoneOutline />}
											disabled={isLoading}
										>
											Concluir Cancelamento
										</Button>
									)}
								</>
							)}
						</Box>
					</Grid>
				</Grid>
			</Grid>

			{openModalCancelConclude && (
				<ModalConcludeCancelProposal isClose={() => setOpenModalCancelConclude(false)} isOpenModal={openModalCancelConclude} rows={rows} />
			)}

			{openModalCancelWaiver && <ModalWaiverCancel isClose={() => setOpenModalCancelWaiver(false)} isOpenModal={openModalCancelWaiver} rows={rows} />}

			{openModalCancelPendency && (
				<ModalPendencyCancelProposal isClose={() => setOpenModalCancelPendency(false)} isOpenModal={openModalCancelPendency} rows={rows} />
			)}

			{openModalRemovePendency && (
				<ModalContext
					isCloseModal={() => setOpenModalRemovePendency(false)}
					isOpenModal={openModalRemovePendency}
					messageType="question"
					showButtonCancel={false}
					showButtonConfirm={false}
					showTitleIcon={false}
					enableBackdropClose
					overrideWidthValue="30%"
					overrideHeightValue="20%"
					ITitle="Pendência"
					IMessage={[pendencyActions]}
				/>
			)}
		</>
	);
};

export default ProposalCanceledCard;
