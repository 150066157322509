import { Property } from "./../../../../../client_api/model/property";
import { Plan } from "../../../../../client_api/model/plan";
import { Environment } from "../../../environment";
import { Api } from "../axios-config";
import { Logs } from "../../../interfaces";
import { IMessage } from "../../../types/message";

export interface IanalysisList {
	id: string;
	cpf: string;
	document: string;
	name: string;
	birthDate: string;
	motherName: string;
	employment_relationship: string;
	maritalStatus: string;
	email: string;
	phone: string;
	rent_months_count: number;
	rental_cost: string;
	send_serasa: string;
	send_click: string;
	property_type: string;
	addressZipcode: string;
	addressStreet: string;
	addressNumber: string;
	addressComplement: string;
	addressCity: string;
	addressState: string;
	status: string;
	updated_at: string;
	signed_in: string;
	expired_at: string;
	expiresAt: string;
	issued_in: string;
	refused_at: string;
	propertyFinally?: string;
	serasa_response: string;
	serasa_analysis_status: string;
	employmentRelationShip: string;
	mobilePhone: string;
	payment_method: string;
	residential_fee: string;
	commercial_fee: string;
	bonus: string;
	lessor_name: string;
	lessor_cpf: string;
	cooperative_tenants: string;
	estateId: string;
	rentAmount: number;
	condominiumAmount: number;
	electricEnergyAmount: number;
	waterAmount: number;
	gasAmount: number;
	iptuAmount: number;
	addressDistrict: string;
	analysis_plan: string;
	lessor_cnpj: string;
	createdFrom: string;
	createdAt: Date;
	isTipping?: string;
	residence_type: string;
	assurance_month_amount: number;
	lmi: number;
	lmi_amount: number;
	lessor: string;
	termBegin: Date;
	expiresSignature: Date;
	canceledType?: string;
	setup_amount?: string;
	client?: {
		id: string;
		document: string;
		name: string;
		status?: string;
	};
	estate?: {
		name?: string;
		type?: string;
		document?: string;
		financial_email?: string;
	};
}

export interface IRulesMaxProposal {
	rules: boolean;
	message: string;
}

export interface IAllAnalysisList {
	name: string;
	employment_relationship: string;
	email: string;
	id?: string;
	rentMonthsCount: number;
	assuranceMonthAmount?: string;
	propertyFinally: string;
	status: string;
	updated_at: string;
	isTipping?: string;
	signed_in: string;
	expired_at: string;
	issued_in: string;
	refused_at: string;
	employmentRelationShip: string;
	residentialFee: string;
	commercialFee: string;
	residential_fee: string;
	commercial_fee: string;
	bonus: string;
	estateId: string;
	rentAmount: string;
	lmi: string;
	lmiAmount: string;
	analysis_plan: string;
	createdFrom: string;
	createdAt: string;
	residence_type: string;
	assurance_month_amount: number;
	created_at: Date;
	termBegin: Date;
	expiresAt: Date;
	planlist: string;
	userId: string;
	expiresSignature: Date;
	client?: {
		id: string;
		document: string;
		name: string;
	};
	estate?: {
		name?: string;
		type?: string;
		document?: string;
		financial_email?: string;
	};
	reasonId?: string;
}

export interface ICreateAnalysis {
	id: string;
	clientId: string;
	typePerson: string;
	document: string;
	name: string;
	status?: string;
	birthDate: string;
	motherName: string;
	maritalStatus: string;
	email: string;
	phone: string;
	propertyFinally: string;
	// analysisPlan?: number | null;

	propertyType: string;
	addressZipcode: string;
	addressStreet: string;
	addressNumber: string;
	addressComplement?: string;
	addressCity: string;
	addressState: string;
	addressIBGE: string;
	addressDistrict: string;

	rentMonthsCount: string;
	rentAmount: string;
	condominiumAmount: string;
	electricEnergyAmount: string;
	waterAmount: string;
	gasAmount: string;
	iptuAmount: string;
	rentalCost: string;
	planlist?: string;
	isTipping?: string;
	sendClick?: string;
	sendSerasa?: string;
	serasaResponse?: string; //campo gravar motivo nao envio serasa

	createdFrom?: string;
	baseCalc?: string;
	creditLimit?: string;
}

export interface IUpdateAnalysisSuperAdmin {
	id?: string;
	status: string;
	assuranceMonthAmount?: string;
	rentAmount: string;
	residentialFee?: string;
	lmi?: string;
	commercialFee?: string;
	fee?: string;
	createdFrom?: string;
	obs?: string;
	updatedAt?: Date;
	planlist: string;
}

export interface IUpAnalysisAproved {
	fee: string;
	lessorName: string;
	termBegin: string;
	lessorCpf: string;
	assuranceMonthAmount: string;
	lmi: string;
	lmiAmount: string;
	rentMonthsCount: string;
	planId?: string;
	lessorTypePerson: string;
	lessorBirthDate?: string;
	lessorEmail: string;
	lessorPhone: string;
	lessorMobilePhone?: string;
	contractBegin?: string;
	contractEnd?: string;
	isTipping?: string;
	sendClick?: string;
	propertyType?: string;
	propertyFinally?: string;
	setup_tax?: boolean;
	charges?: boolean;
}

export interface IPlan {
	id: string;
	lmi: string;
	status: string;
	clientId: string;
	planId: string;
	productId: string;
	residentialFee: string;
	commercialFee: string;
	flag_analysis: string;
	flag_renovation: string;
	flag_rateRent: string;
	//flagRateCharge: string;
	flag_rateCharge: string;
	coverage: string;
	setup_transfer: string;
	proposalMin: string;
	plan: Plan;
}

export type Client = {
	id: string;
	document: string;
	name: string;
	baseRateCalc: string;
	activeSignature: number;
	tax_setup_enable: number;
	tax_setup_limit_default: string;
	tax_setup_limit_min: string;
	tax_setup_limit_max: string;
};

export interface BiometryDetails {
	id: string;
	analysisId: string;
	platformId: string;
	gateway: string;
	status: string;
	integratorStatus: string;
	message: string;
	urlShort: string;
	cancelReason: any;
	cancelDate: any;
	createdAt: string;
	updatedAt: any;
	expiresIn: string;
	expiresAt: any;
	authenticatedAt: any;
}

export interface IDetalheAnalises {
	fee: string;
	cpf: string;
	document: string;
	name: string;
	birthDate: string;
	motherName: string;
	employment_relationship: string;
	maritalStatus: string;
	email: string;
	id?: string;
	creditLimit: string;
	phone: string;
	rentMonthsCount: number;
	assuranceMonthAmount?: string;
	rental_cost: string;
	isTipping?: string;
	sendSerasa: string;
	send_click: string;
	propertyType: string;
	propertyFinally: string;
	addressZipcode: string;
	addressStreet: string;
	addressNumber: string;
	addressComplement: string;
	addressCity: string;
	addressState: string;
	status: string;
	updated_at: string;
	signed_in: string;
	expired_at: string;
	issued_in: string;
	refused_at: string;
	serasa_response: string;
	serasa_analysis_status: string;
	serasaResponse: string;
	serasaAnalysisStatus: string;
	employmentRelationShip: string;
	mobilePhone: string;
	payment_method: string;
	residentialFee: string;
	commercialFee: string;
	residential_fee: string;
	commercial_fee: string;
	bonus: string;
	lessorName: string;
	lessorCpf: string;
	lessorPhone: string;
	lessorMobilePhone?: string;
	lessorBirthDate?: string;
	lessorEmail?: string;
	lessorTypePerson?: string;
	cooperative_tenants: string;
	estateId: string;
	rentAmount: string;
	lmi: string;
	lmiAmount: string;
	condominiumAmount: string;
	electricEnergyAmount: string;
	waterAmount: string;
	gasAmount: string;
	iptuAmount: string;
	addressDistrict: string;
	analysis_plan: string;
	lessor_cnpj: string;
	createdFrom: string;
	createdAt: string;
	residence_type: string;
	assurance_month_amount: number;
	lessor: string;
	created_at: Date;
	termBegin: Date;
	expiresAt: Date;
	planlist: string;
	obs: string;
	userId: string;
	expiresSignature: Date;
	baseCalc?: string;
	setup_tax?: string;
	client?: {
		id: string;
		document: string;
		name: string;
		baseRateCalc: string;
		activeSignature: number;
		tax_setup_enable: 1;
		tax_setup_limit_default: string;
		tax_setup_limit_min: string;
		tax_setup_limit_max: string;
	};
	estate?: {
		name?: string;
		type?: string;
		document?: string;
		financial_email?: string;
	};
	canceledReason?: string;
	canceledType?: string;
	reasonId?: string;
	transaction?: {
		id: string
		status: string
		rawAmount: string
	},
	installmentSetup?: {
		id: string
		email: string
	}
}

type TAnalisesTotalCount = {
	data: IanalysisList[];
	totalCount: number;
};
export interface ITotalAnalisesPorMes {
	mes: string;
	ano: Number;
	quantidade: Number;
	aluguel: Number;
	lmi_total: Number;
	lmi_saldo: Number;
	valor_parcela: Number;
}

export interface ICancelAnalysis {
	canceledType: string;
	canceledReason: string;
}
export interface IAnalisesStatus {
	status: String;
	quantidade: Number;
}

export interface ITopAnalises {
	imobiliaria: String;
	uuid: String;
	imobiliaria_id: Number;
	analisadas: Number;
	aprovadas: Number;
}

export type TTopAnalises = {
	results: ITopAnalises[];
	total: String;
};

export type TCountAnalysis = {
	total: number;
};

export interface IAnalisesPorDia {
	dia: String;
	analisadas: number;
	aprovadas: number;
}

export interface IAnalysisTotalStatus {
	periodo: string;
	analises: string;
	aprovadas: string;
	garantias: string;
}

export type TAnalysisCountStatus = {
	data: IAnalysisTotalStatus[];
	totalCount: number;
};

export interface ISendEmail {
	email: string;
	id?: string;
	phone?: string;
}

export interface ISendBiometria {
	email: string;
	id?: string;
	phone: string;
}

const getAnalysisStatus = async (): Promise<IAnalysisTotalStatus[] | Error> => {
	try {
		const { data } = await Api().get(`/analysis/graphsixmonth`);
		if (data) {
			return data;
		}
		return new Error("Erro ao consultar o registro.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getTotal = async (): Promise<TCountAnalysis | Error> => {
	try {
		const { data } = await Api().get(`/analysis/total`);
		if (data) {
			return data;
		}
		return new Error("Erro ao consultar o registro.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getTotalAproved = async (): Promise<TCountAnalysis | Error> => {
	try {
		const { data } = await Api().get(`/analysis/total?status=issued`);
		if (data) {
			return data;
		}
		return new Error("Erro ao consultar o registro.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getAll = async (
	page = 1,
	busca = "",
	tipo = "",
	origem = "",
	status = "",
	dataini = "",
	datafim = "",
	clientFilter = "",
	managerId = "",
	limit = 10
): Promise<TAnalisesTotalCount | Error> => {
	try {
		const urlRelativa = `/analysis?_page=${page}&_limit=${limit || Environment.LIMITE_LINHAS
			}&nome_like=${busca}&tipo=${tipo}&origem=${origem}&status=${status}&dataini=${dataini}&datafim=${datafim}&clientId=${clientFilter}&managerId=${managerId}`;
		const { data, headers } = await Api().get(urlRelativa);

		if (data.results) {
			return {
				data: data.results,
				totalCount: data.total,
			};
		}

		return new Promise((_, reject) => reject(new Error("Erro ao listar registros.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const updateEmail = async (dados: Omit<ISendEmail, "id">): Promise<ISendEmail | Error> => {
	try {
		const { data } = await Api().post<ISendEmail>("/analysis/clicksign-email", dados);
		if (data) return data;

		return new Promise((_, reject) => reject(new Error("Erro ao reenviar email.")));
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getById = async (id: string): Promise<IDetalheAnalises | Error> => {
	try {
		const { data } = await Api().get(`/analysis/${id}`);
		if (data) {
			return data;
		}
		return new Promise((_, reject) => reject(new Error("Erro ao consultar o registro.")));
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getDetails = async (id: string): Promise<IDetalheAnalises | Error> => {
	try {
		const { data } = await Api().get(`/analysis/${id}/details`);
		if (data) {
			return data;
		}
		return new Error("Erro ao consultar o registro.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getBiometryDetails = async (id: string): Promise<BiometryDetails> => {
	try {
		const { data } = await Api().get(`/biometria/analysis/${id}`);
		return data;
	} catch (error) {
		console.error(error);
		throw new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

//const create = async (dados: Omit<ICreateAnalysis, 'id'>): Promise<ICreateAnalysis | Error> => {
const create = async (dados: Omit<ICreateAnalysis, "id">, id: string): Promise<ICreateAnalysis | Error> => {
	try {
		//  console.log('send....',dados.sendClick,dados.sendSerasa);
		// dados.sendClick = dados.sendClick=="false"?"not_send":"send";
		// dados.sendSerasa    = dados.sendSerasa=="false"?"not_send_serasa":"send_serasa";
		//  console.log('send...depois.',dados.sendClick,dados.sendSerasa);
		console.log("istiping....", dados.isTipping);
		// dados.isTipping = dados.isTipping ? '1': '0';

		const { data } = await Api().post<ICreateAnalysis>("/analysis", {
			...dados,
			id: id,
		});
		if (data) {
			return data;
		}
		return new Error("Erro ao criar o registro.");
	} catch (error) {
		console.log(error);
		return new Error((error as { message: string }).message || "Erro ao criar o registro.");
	}
};

const generateProposal = async (id: string) => {
	try {
		await Api().get(`/analysis/generateproposal/${id}`);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao criar o registro.");
	}
};

const updateById = async (id: string, dados: IUpAnalysisAproved): Promise<any | Error> => {
	try {
		dados.sendClick = dados.sendClick == "false" ? "not_send" : "send";
		//dados.setup_tax = dados.setup_tax == "true" ? true : false;
		delete dados.propertyFinally;
		delete dados.propertyType;
		const { data } = await Api().patch(`/analysis/approved/${id}`, dados);
		return data;
	} catch (error) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const notificationSend = async (subject: string, description: string): Promise<any | Error> => {
	try {
		const result = await Api().post(`/notification/send`);
		return result;
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao enviar notificação.");
	}
};

const updateCanceledId = async (id: string, dados: ICancelAnalysis): Promise<void | Error> => {
	try {
		await Api().patch(`/analysis/canceled/${id}`, dados);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao atualizar o registro.");
	}
};

const updateSendBiometria = async (id: string, dados: ISendBiometria): Promise<void | Error> => {
	try {
		await Api().post(`/analysis/altera-credencial/${id}`, dados);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao atualizar o registro.");
	}
};

const resendAnalysis = async (id: string, numero: string): Promise<any | Error> => {
	try {
		const result = await Api().get(`/analysis/resend/${id}/${numero}`);
		return result;
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao reenviar análise.");
	}
};

const deleteById = async (id: string): Promise<void | Error> => {
	try {
		await Api().delete(`/analises/${id}`);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao apagar o registro.");
	}
};

const AnalisesPorMes = async (dataini = "", datafim = ""): Promise<ITotalAnalisesPorMes[] | Error> => {
	try {
		const urlRelativa = `/analisesPorMes?dataini=${dataini}&datafim=${datafim}`;
		const { data, headers } = await Api().get(urlRelativa);
		if (data) {
			return data;
		}
		return new Error("Erro ao listar registros.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao listar registros.");
	}
};

const AnalisesPorStatus = async (status = "", dataini = "", datafim = ""): Promise<IAnalisesStatus[] | Error> => {
	try {
		const urlRelativa = `/analisesstatuscount?status=${status}&dataini=${dataini}&datafim=${datafim}`;
		const { data, headers } = await Api().get(urlRelativa);
		if (data) {
			return data;
		}
		return new Error("Erro ao listar registros. ");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Error ao buscar analises por status");
	}
};

const AnalisesPorDia = async (dataini = "", datafim = ""): Promise<IAnalisesPorDia[] | Error> => {
	try {
		const urlRelativa = `/analisespordia?dataini=${dataini}&datafim=${datafim}`;
		const { data, headers } = await Api().get(urlRelativa);
		if (data) {
			return data;
		}

		return new Error("Erro ao listar registros. ");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Error ao buscar analises por status");
	}
};

const TopAnalises = async (limit = "10", dataini = "", datafim = ""): Promise<ITopAnalises[] | Error> => {
	try {
		const urlRelativa = `/analisestopestate?limit=${limit}&dataini=${dataini}&datafim=${datafim}`;
		const { data, headers } = await Api().get(urlRelativa);
		if (data) {
			return data;
		}
		return new Error("Erro ao listar registros. ");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Error ao buscar analises por status");
	}
};

const AnalisesTotal = async (dataini = "", datafim = ""): Promise<number | Error> => {
	try {
		const urlRelativa = `/totalanalises?dataini=${dataini}&datafim=${datafim}`;
		const { data, headers } = await Api().get(urlRelativa);
		if (data) {
			return data;
		}
		return 0;
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Error ao buscar analises por status");
	}
};

const getLogs = async (analysisId: String): Promise<Logs[] | Error> => {
	try {
		const { data } = await Api().get(`/logs/${analysisId}`);
		if (data) return data;

		return new Error("Erro ao consultar o registro de logs da análise.");
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro de logs da análise.");
	}
};

const verifyCellPhoneAndCPF = async (document: string, cellphone: string): Promise<any> => {
	///string | Error> => {
	try {
		const { data } = await Api().post("/analysis/verify/document-cellphone", {
			document,
			cellphone,
		});

		return data;
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const updateAnalysisSuperAdmin = async (dataInfo: IUpdateAnalysisSuperAdmin): Promise<IMessage<null> | Error> => {
	const { id } = dataInfo;

	try {
		const { data } = await Api().patch(`/analysis/update/superadmin/analysis/${id}`, dataInfo);

		if (!data) return new Promise((_, reject) => reject(new Error("Não foi possível de atualizar a análise do id específico: " + id)));

		return new Promise((resolve) =>
			resolve({
				message: "Sucesso!",
				description: "Análise atualizado com sucesso!",
			})
		);
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const findAllAnalysis = async (clientId: string | null): Promise<IAllAnalysisList[] | Error> => {
	try {
		const { data } = await Api().get(`/analysis/all/analysis?clientId=${clientId}`);

		if (!data || !data.result)
			return new Promise((_, reject) => reject(new Error("Não foi possível buscar as informações de análises com expectivos bõnus. Verifique!")));

		return new Promise((resolve) => resolve(data.result));
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const rulesMaxProposal = async (document: string, propertyFinally: string): Promise<IRulesMaxProposal> => {
	try {
		const { data } = await Api().get(`/analysis/regra-total-garantias/${document}/${propertyFinally}`);

		if (!data) return new Promise((_, reject) => reject(new Error("Não foi possível buscar as informações de análises com expectivos bõnus. Verifique!")));

		return new Promise((resolve) => resolve(data));
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

export const analysisService = {
	getAll,
	getById,
	create,
	updateById,
	deleteById,
	AnalisesPorMes,
	AnalisesPorStatus,
	TopAnalises,
	AnalisesTotal,
	AnalisesPorDia,
	updateCanceledId,
	generateProposal,
	getAnalysisStatus,
	getTotalAproved,
	getTotal,
	updateEmail,
	getDetails,
	getBiometryDetails,
	getLogs,
	verifyCellPhoneAndCPF,
	updateAnalysisSuperAdmin,
	findAllAnalysis,
	rulesMaxProposal,
	updateSendBiometria,
	resendAnalysis,
	notificationSend,
};
