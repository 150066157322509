"use client";

import { PublishedWithChanges } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Alert, Box, Button, Card, CardContent, Divider, Grid, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ToastContent } from "../../../../../shared/components";
import { IVFormErros, useVForm, VForm, VTextField } from "../../../../../shared/forms";
import { VMaskTextField } from "../../../../../shared/forms/VMaskTextField";
import { useToast } from "../../../../../shared/hooks";
import { ProposalDocumentsService } from "../../../../../shared/services/api/proposal/ProposalDocumentsService";
import { ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";
import { globalMaskReal, globalRemoveMaskRealDecimais } from "../../../../../shared/utils";
import { IFormState } from "../../interfaces/IFormRenovateProposal";
import { calculateDatesDateFns, validateRentAmount } from "../helpers/proposalRenovateHelpers";
import ModalAttachmentRenovate from "./modalAttachmentRenovate";
import ModalPlanRenovate from "./modalPlanRenovate";
import { formValidationSchema } from "./validation-schema";

interface RentalFormProps {
	onFileSelected?: (file: File) => void; // Prop para capturar o arquivo anexado
	row: any; // Prop contendo os dados iniciais do formulário
	onSubmit: () => void;
}

const RentalForm: React.FC<RentalFormProps> = ({ onFileSelected, row }) => {
	const { formRef, save } = useVForm();
	const [isLoading, setIsLoading] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [attachedFileName, setAttachedFileName] = useState<string | null>(null); // Nome do arquivo anexado
	const [adjustmentMessage, setAdjustmentMessage] = useState<string | null>(null);
	const [isRentAmountHighlighted, setIsRentAmountHighlighted] = useState(false);
	const [isModalPlan, setIsModalPlan] = useState(false);
	const toastHandler = useToast();
	const [attachedFile, setAttachedFile] = useState<File | null>(null);
	const navigate = useNavigate();
	const [newRentAmount, setNewRentAmount] = useState<string>("0");
	const [newIPTU, setNewIPTU] = useState<string>("0");
	const [newCondominium, setNewCondominium] = useState<string>("0");

	const weeks = Array.from({ length: 48 - 12 + 1 }, (_, i) => i + 12); // Opções de 12 a 48 meses

	const [formState, setFormState] = useState<IFormState>({
		rentMonthsCount: row?.rentMonthsCount || "12",
		paymentMethod: row?.paymentMethod || "Boleto",
		contractBegin: row?.contractBegin || "",
		contractEnd: row?.contractEnd || "",
		termBegin: row?.termBegin || "",
		expiresAt: row?.expiresAt || "",
		rentAmount: row?.rentAmount || "0",
		condominiumAmount: row?.condominiumAmount || "0",
		electricEnergyAmount: row?.electricEnergyAmount || "0",
		waterAmount: row?.waterAmount || "0",
		gasAmount: row?.gasAmount || "0",
		iptuAmount: row?.iptuAmount || "0",
		email: row?.email || "",
		propertyFinally: row?.propertyFinally || "residential",
	});

	useEffect(() => {
		if (row) {
			const { termBegin, expiresAt, contractEnd } = calculateDatesDateFns(row.expiresAt || "", row.rentMonthsCount || "12");

			setFormState((prevState) => ({
				...prevState,
				...row, // Carrega os valores da row diretamente
				termBegin,
				expiresAt,
				contractEnd,
			}));

			formRef.current?.setData({
				...row,
				termBegin,
				expiresAt,
				contractEnd,
			});
		}
	}, [row]);

	const handleCloseModalPlan = () => {
		setIsModalPlan(false);
	};

	const handleRentAmountBlur = (value: string) => {
		value = globalRemoveMaskRealDecimais(value);
		const validatedRentAmount = validateRentAmount(value, row.propertyFinally, {
			maxCommercial: row?.maxCommercial || "0",
			maxResidential: row?.maxResidential || "0",
			minCommercial: row?.minCommercial || "0",
			minResidential: row?.minResidential || "0",
			rate: row?.rate || "0",
			initialRentAmount: row?.rentAmount || "0",
		});

		setNewRentAmount(validatedRentAmount);
		if (validatedRentAmount !== value) {
			setFormState((prevState) => ({
				...prevState,
				rentAmount: validatedRentAmount,
			}));

			formRef.current?.setFieldValue("rentAmount", validatedRentAmount);
			setNewRentAmount(validatedRentAmount);

			// Exibe mensagem de ajuste
			setAdjustmentMessage(
				`O valor do aluguel foi ajustado para ${globalMaskReal(
					validatedRentAmount
				)} devido às regras de limite. Caso precise de um valor maior, favor entrar em contato com setor comercial.`
			);

			// Ativa o destaque no campo
			setIsRentAmountHighlighted(true);
			setTimeout(() => setIsRentAmountHighlighted(false), 10000);
			setTimeout(() => setAdjustmentMessage(null), 10000);
		}
	};

	const handleCondominiumAmountBlur = (value: string) => {
		value = globalRemoveMaskRealDecimais(value);
		setNewCondominium(value);
	};

	const handleIPTUAmountBlur = (value: string) => {
		value = globalRemoveMaskRealDecimais(value);
		setNewIPTU(value);
	};

	const handleInputChange = (field: string, value: string) => {
		setFormState((prevState) => ({
			...prevState,
			[field]: value,
		}));

		// Recalcular datas, se necessário
		if (field === "rentMonthsCount" || field === "termBegin") {
			const { termBegin, expiresAt, contractEnd } = calculateDatesDateFns(
				row?.expiresAt || "",
				field === "rentMonthsCount" ? value : formState.rentMonthsCount
			);

			setFormState((prevState) => ({
				...prevState,
				termBegin,
				expiresAt,
				contractEnd,
			}));

			formRef.current?.setFieldValue("termBegin", termBegin);
			formRef.current?.setFieldValue("expiresAt", expiresAt);
			formRef.current?.setFieldValue("contractEnd", contractEnd);
		}
	};

	const handleValidateAndOpenModal = async (dadosForm: any) => {
		try {
			// Valida o formulário
			await formValidationSchema.validate(dadosForm, { abortEarly: false });

			// Verifica se o anexo é obrigatório e está ausente
			if (Number(formState.rentMonthsCount) > 12 && !attachedFile) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={"É necessário anexar o termo de aditivo de contrato para continuar."} />,
				});
				return; // Impede a abertura do modal
			}

			setFormState(dadosForm);
			setIsModalPlan(true);
		} catch (error) {
			if (error instanceof yup.ValidationError) {
				const validationErrors: IVFormErros = {};
				error.inner.forEach((err) => {
					if (err.path) validationErrors[err.path] = err.message;
				});
				formRef.current?.setErrors(validationErrors);
			}
		}
	};

	const handleSave = async (dados: any) => {
		setIsLoading(true);
		try {
			const cleanedData = {
				...dados,
				rentAmount: globalRemoveMaskRealDecimais(dados.rentAmount),
				condominiumAmount: globalRemoveMaskRealDecimais(dados.condominiumAmount),
				electricEnergyAmount: globalRemoveMaskRealDecimais(dados.electricEnergyAmount),
				waterAmount: globalRemoveMaskRealDecimais(dados.waterAmount),
				gasAmount: globalRemoveMaskRealDecimais(dados.gasAmount),
				iptuAmount: globalRemoveMaskRealDecimais(dados.iptuAmount),
				contractBegin: dados.contractBegin.slice(0, 10) + " 03:00:00",
				contractEnd: dados.contractEnd.slice(0, 10) + " 03:00:00",
				termBegin: dados.termBegin.slice(0, 10) + " 03:00:00",
				expiresAt: dados.expiresAt.slice(0, 10) + " 03:00:00",
			};
			// Envia os dados para a API e obtém o novo ID
			const result = await ProposalService.RenovateProposal(row.id, cleanedData);

			if (result instanceof Error) {
				console.error("Erro ao criar renovação:", result.message);
			} else {
				const newId = result.id;
				if (attachedFile && newId && newId !== undefined) {
					await new Promise((resolve) => setTimeout(resolve, 1000));
					const fileUploadResult = await ProposalDocumentsService.uploadRenovateProposalDocument(newId, "contract_adictive", attachedFile);

					if (fileUploadResult instanceof Error) {
						throw fileUploadResult;
					}
					console.log("Arquivo enviado com sucesso!");
				}
			}
			toastHandler.present({
				type: "success",
				position: "top-right",
				messageOrContent: <ToastContent title={"Sucesso"} content={"Renovação criada e arquivo enviado com sucesso!"} />,
			});
		} catch (error) {
			console.error("Erro ao salvar ou enviar arquivo:", error);
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={"Erro"} content={"Falha ao criar a renovação ou enviar o arquivo"} />,
			});
			handleFileRemove();
		} finally {
			setIsLoading(false);
			navigate("/garantias");
		}
	};

	// Função para abrir o modal
	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	// Função para fechar o modal
	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	// Função para anexar o arquivo
	const handleFileAttach = (file: File) => {
		setAttachedFile(file); // Salva o arquivo real no estado
		setAttachedFileName(file.name); // Salva o nome do arquivo (opcional, para exibição)
		onFileSelected?.(file); // Chama a função de callback, se existir
		handleCloseModal(); // Fecha o modal
	};

	// Função para remover o arquivo
	const handleFileRemove = () => {
		setAttachedFile(null); // Remove o arquivo do estado
		setAttachedFileName(null); // Limpa o nome do arquivo
	};

	const handleSelectPlan = (plan: any) => {
		// Mescla os dados do formulário com os dados do plano
		const mergedData = {
			...formState, // Dados do formulário validados
			planId: plan.id,
			assuranceMonthAmount: plan.price || "",
			lmi: plan.lmi || "",
			lmiAmount: plan.lmiValue || "",
			fee: plan.taxa || "",
			rentMonthsCount: plan.rentMonthsCount || formState.rentMonthsCount,
			commercialFee: plan.taxa || "",
			residentialFee: plan.taxa || "",
		};

		// Fecha o modal e salva os dados
		setIsModalPlan(false);
		handleSave(mergedData);
	};

	const shouldShowAttachment = Number(formState.rentMonthsCount) > 12; // Condição para exibir o botão e alerta

	return (
		<Card sx={{ margin: "auto", px: 2, borderRadius: 4 }}>
			<CardContent>
				<VForm ref={formRef} onSubmit={handleSave} onPointerEnterCapture={""} onPointerLeaveCapture={""} placeholder={""}>
					{/* Seção: Dados da Renovação */}
					<Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
						Dados da Renovação
					</Typography>
					<Divider sx={{ mb: 2 }} />
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<VMaskTextField
								fullWidth
								label="Início do contrato de locação"
								name="contractBegin"
								type="date"
								mask="date"
								value={formState.contractBegin}
								onChange={(e) => handleInputChange("contractBegin", e.target.value)}
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<VMaskTextField
								fullWidth
								label="Fim do contrato de locação"
								name="contractEnd"
								type="date"
								mask="date"
								value={formState.contractEnd}
								onChange={(e) => handleInputChange("contractEnd", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<VTextField
								fullWidth
								label="Quantidade de meses Vigência"
								name="rentMonthsCount"
								select
								value={formState.rentMonthsCount}
								onChange={(e) => handleInputChange("rentMonthsCount", e.target.value)}
								aria-label="Selecione a quantidade de meses da vigência"
							>
								{weeks.map((week) => (
									<MenuItem key={week} value={week.toString()}>
										{week}
									</MenuItem>
								))}
							</VTextField>
						</Grid>
						<Grid item xs={12} md={6}>
							<VMaskTextField
								fullWidth
								label="Início da Vigência"
								name="termBegin"
								type="date"
								mask="date"
								value={formState.termBegin}
								onChange={(e) => handleInputChange("termBegin", e.target.value)}
								disabled
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<VMaskTextField
								fullWidth
								label="Data de Expiração"
								name="expiresAt"
								type="date"
								mask="date"
								value={formState.expiresAt}
								onChange={(e) => handleInputChange("expiresAt", e.target.value)}
								disabled
							/>
						</Grid>
						{shouldShowAttachment && (
							<Grid item xs={12}>
								{attachedFileName ? (
									<Alert severity="success" sx={{ my: 2 }}>
										Arquivo anexado: <strong>{attachedFileName}</strong>
									</Alert>
								) : (
									<Alert severity="error" sx={{ my: 2 }}>
										Obrigatório anexar o arquivo do termo de aditivo de contrato
									</Alert>
								)}
								<Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
									<Button
										variant="contained"
										color="primary"
										onClick={handleOpenModal}
										startIcon={attachedFileName ? <PublishedWithChanges /> : <AttachFileIcon />} // Ícone dinâmico
									>
										{attachedFileName ? "Alterar Termo Aditivo de Contrato" : "Anexar Termo Aditivo de Contrato"}
									</Button>
								</Box>
							</Grid>
						)}
					</Grid>

					{/* Modal de Anexo */}
					<ModalAttachmentRenovate
						isOpen={isModalOpen}
						onClose={handleCloseModal}
						onAttach={handleFileAttach}
						onRemove={handleFileRemove}
						existingFileName={attachedFileName}
					/>

					{/* Seção: Valores da Locação */}
					<Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
						Valores da Locação
					</Typography>
					<Divider sx={{ mb: 2 }} />
					<Grid container spacing={2}>
						{adjustmentMessage && (
							<Grid item xs={12}>
								<Alert severity="warning" onClose={() => setAdjustmentMessage(null)} sx={{ my: 2 }}>
									{adjustmentMessage}
								</Alert>
							</Grid>
						)}
						<Grid item xs={12} md={6}>
							<VMaskTextField
								fullWidth
								label="Valor do Aluguel"
								name="rentAmount"
								mask="real"
								value={formState.rentAmount}
								onChange={(e) => handleInputChange("rentAmount", e.target.value)}
								onBlur={(e) => handleRentAmountBlur(e.target.value)}
								sx={{
									backgroundColor: isRentAmountHighlighted ? "#fff4e5" : "inherit", // Cor de fundo amarelo claro
									transition: "background-color 0.8s ease", // Transição suave
								}}
							/>
						</Grid>

						<Grid item xs={12} md={6}>
							<VMaskTextField
								fullWidth
								label="Valor do Condomínio"
								name="condominiumAmount"
								mask="real"
								value={formState.condominiumAmount}
								onChange={(e) => handleInputChange("condominiumAmount", e.target.value)}
								onBlur={(e) => handleCondominiumAmountBlur(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<VMaskTextField
								fullWidth
								label="Valor da Energia Elétrica"
								name="electricEnergyAmount"
								mask="real"
								value={formState.electricEnergyAmount}
								onChange={(e) => handleInputChange("electricEnergyAmount", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<VMaskTextField
								fullWidth
								label="Valor da Água"
								name="waterAmount"
								mask="real"
								value={formState.waterAmount}
								onChange={(e) => handleInputChange("waterAmount", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<VMaskTextField
								fullWidth
								label="Valor do Gás"
								name="gasAmount"
								mask="real"
								value={formState.gasAmount}
								onChange={(e) => handleInputChange("gasAmount", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<VMaskTextField
								fullWidth
								label="Valor do IPTU"
								name="iptuAmount"
								mask="real"
								value={formState.iptuAmount}
								onChange={(e) => handleInputChange("iptuAmount", e.target.value)}
								onBlur={(e) => handleIPTUAmountBlur(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<VTextField
								fullWidth
								label="E-mail"
								name="email"
								value={formState.email}
								onChange={(e) => handleInputChange("email", e.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<VTextField
								fullWidth
								label="Método de Pagamento"
								name="paymentMethod"
								select
								value={formState.paymentMethod}
								onChange={(e) => handleInputChange("paymentMethod", e.target.value)}
							>
								<MenuItem value="billet">Boleto</MenuItem>
							</VTextField>
						</Grid>
					</Grid>

					{/* Botão de salvar */}
					<Box sx={{ mt: 4 }}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => handleValidateAndOpenModal(formRef.current?.getData())}
							disabled={isLoading}
							fullWidth
						>
							{isLoading ? "Salvando..." : "Renovar"}
						</Button>
					</Box>
				</VForm>

				<ModalPlanRenovate
					isOpen={isModalPlan}
					onClose={handleCloseModalPlan}
					onSelectPlan={handleSelectPlan}
					row={row}
					updatedRentAmount={newRentAmount}
					updateIPTU={newIPTU}
					updateCondominium={newCondominium}
				/>
			</CardContent>
		</Card>
	);
};

export default RentalForm;
