import { EmailOutlined } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import SaveIcon from "@mui/icons-material/Save";
import {
	Button,
	CircularProgress,
	Divider,
	Grid,
	Icon,
	IconButton,
	InputAdornment,
	LinearProgress,
	List,
	ListItem,
	MenuItem,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { isEmail } from "class-validator";
import { isObject, set } from "lodash";
import moment from "moment";
import "moment-timezone";
import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useAuthContext } from "../../../context";
import { useProfile } from "../../../context/ProfileContext";
import { BasicModal, ModalContext, ModalResultAnalysis, ToastContent, ToolbarAnalysis } from "../../../shared/components";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { IVFormErros, useVForm, VForm, VTextField } from "../../../shared/forms";
import { VMaskTextField } from "../../../shared/forms/VMaskTextField";
import { checkByProfileGuard } from "../../../shared/guards/ProfileGuard";
import { useToast } from "../../../shared/hooks";
import { Logs } from "../../../shared/interfaces";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import {
	analysisService,
	BiometryDetails,
	ICancelAnalysis,
	IDetalheAnalises,
	IRulesMaxProposal,
	ISendBiometria,
	ISendEmail,
	IUpdateAnalysisSuperAdmin,
} from "../../../shared/services/api/analysis/analysisService";
import { ISerasaData, ISerasaResponse, serasaService } from "../../../shared/services/api/serasa/serasaService";
import { IDetalheUsuario, userService } from "../../../shared/services/api/user/userService";
import { IMessage } from "../../../shared/types/message";
import { maskPhone } from "../../../shared/utils";
import { formatDate } from "../../../shared/utils/format-date";
import { formatScaleValue } from "../../../shared/utils/formatScaleValue";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { DetailAnalisys } from "./DetailAnalysis";
import { DetailLocator } from "./DetailLocator";
import { DetailTenant } from "./DetailTenant";
import { DetailUser } from "./DetailUser";
import { SerasaResponseRejectedModalContent } from "./serasa-response-rejected/serasa-response-rejected-modal-content";
import { format } from "date-fns";
import { is } from "date-fns/locale";
import ModalDetailSerasaResponse from "./ModalDetailSerasaResponse";
import { FinancialService } from "../../../shared/services/api/financial/FinancialService";

interface IFormData {
	document: string;
	name: string;
	motherName: string;
	ana_profissao?: string;
	birthDate?: string;
	marital_status: string;
	email: string;
	ana_meses_aluguel?: number;
	ana_custo_aluguel?: string;
	serasa_response?: string;
	ana_tipo_aluguel?: string;
	address_zipcode: string;
	address_street: string;
	address_number: string;
	residence_typeo?: string;
	address_complement?: string;
	address_district: string;
	address_city: string;
	address_state: string;
	status: string;
	createdAt?: string;
	updated_at?: string;
	ana_vigencia_inicio?: string;
	ana_vigencia_fim?: string;
	ana_tipo_pagamento?: string;
	ana_residencial_fee?: number;
	ana_comercial_fee?: number;
	ana_locador_nome?: string;
	ana_cliente_id?: number;
	ana_plano_analise?: string;
	ana_valor_aluguel?: number;
}

interface IPlan {
	id: string;
	lmi: string;
	status: string;
	clientId: string;
	planId: string;
	productId: string;
	residentialFee: string;
	commercialFee: string;
	flagAnalysis: string;
	flagRenovation: string;
	coverage: string;
	setup_transfer: string;
	proposalMin: string;
	plan: any[];
}

const removeMask = (value: string) => {
	return value
		.replace(/\D/g, "")
		.replace(/[^0-9]/g, "")
		.replace(/(\d{1,2})$/, ".$1");
};

const formValidationSchema: yup.SchemaOf<ISendEmail> = yup.object().shape({
	email: yup.string().required("Campo Obrigatório"),
	id: yup.string().notRequired(),
	phone: yup.string().notRequired(),
});

const formValidationSchemaBiometria: yup.SchemaOf<ISendBiometria> = yup.object().shape({
	email: yup.string().required("Campo Obrigatório"),
	id: yup.string().notRequired(),
	phone: yup.string().required("Campo Obrigatório"),
});

const formEditValidationSchema: yup.SchemaOf<any> = yup.object().shape({
	id: yup.string().required("Campo Obrigatório"),
	status: yup.string().required("Campo Obrigatório"),
	obs: yup.string().default(""),
	createdFrom: yup.string().required("Campo Obrigatório"),
	assuranceMonthAmount: yup.string().default(""),
	rentAmount: yup.string().required("Campo Obrigatório"),
	lmi: yup.string().default(""),
	residentialFee: yup.string().default(""),
	commercialFee: yup.string().default(""),
	fee: yup.string().default(""),
	planlist: yup.string().required("Campo Obrigatório"),
	updatedAt: yup.date(),
});

const convertStatusSerasa = (status: string = "Não fornecida") => {
	if (status === "approved") return "Aprovado";
	else if (status === "refused") return "Reprovado";

	return status;
};

const maskReal = (value: string) => {
	return value
		.replace(/\D/g, "")
		.replace(/(\d{1,2})$/, ",$1")
		.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const AnalysisDetailPresentation: React.FC = () => {
	const { id = "novo" } = useParams<"id">();
	const navigate = useNavigate();
	const { formRef } = useVForm();
	const { formRef: formRefEdit } = useVForm();
	const { formRef: formRefBiometria } = useVForm();
	const [isLoading, setIsLoading] = useState(false);
	const [nome, setNome] = useState("");
	const [doc, setDoc] = useState("");
	const [docLessor, setDocLessor] = useState("");
	const [status, setStatus] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const [rows, setRows] = useState<IDetalheAnalises>();
	const [biometryRows, setBiometryRows] = useState<BiometryDetails>();
	const [plan, setPlan] = useState<IPlan[]>([]);
	const [user, setUser] = useState<IDetalheUsuario>();
	const [rentAmount, setRentAmount] = useState<number>(0);
	const [assuranceBaseCalc, setAssuranceBaseCalc] = useState<number>(0);
	const [chargesBaseCalc, setChargesBaseCalc] = useState<number>(0);
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const [openModalResultAnalysis, setOpenModalResultAnalysis] = useState(false);
	const [openModalCancel, setOpenModalCancel] = useState(false);
	const [openModalGenerateProposal, setOpenModalGenerateProposal] = useState(false);
	const [reasonCancel, setReasonCancel] = useState("");
	const [propFinally, setProFinally] = useState("");
	const [reSendEmail, setReSendEmail] = useState(false);
	const [email, setEmail] = useState("");
	const [prevEmail, setPrevEmail] = useState("");
	const [prevPhone, setPrevPhone] = useState("");
	const [modalReAnalysis, SetModalReAnalisys] = useState(false);
	const [openModalSerasaResponse, setOpenModalSerasaResponse] = useState(false);
	const [openModalLogs, setOpenModalLogs] = useState(false);
	const [openModalLogsPayload, setOpenModalLogsPayload] = useState(false);
	const [isLoadingLogs, setIsLoadingLogs] = useState(false);
	const [dataLogs, setDataLogs] = useState<Logs[]>([]);
	const [payload, setPayload] = useState<any>(undefined);
	const [idLog, setIdLog] = useState("");
	const { loggedUser } = useAuthContext();
	const { permissionLevel, isSuperAdmin } = useProfile();
	const [openModalEditSuper, setOpenModalEditSuper] = useState(false);
	const [isLoadingEditSuper, setIsLoadingEditSuper] = useState(false);
	const toastHandler = useToast();
	const [isLoadingLogsSerasa, setIsLoadingLogsSerasa] = useState(false);
	const [dataSerasa, setDataSerasa] = useState<ISerasaData | undefined>();
	const [openModalLogsSerasa, setOpenModalLogsSerasa] = useState(false);
	const [dataSerasaResponse, setDataSerasaResponse] = useState<ISerasaResponse | undefined>();
	const [createdFrom, setCreatedFrom] = useState<any>(null);
	const [rulesMaxProposal, setRulesMaxProposal] = useState<IRulesMaxProposal>({
		rules: false,
		message: "",
	});
	const [openModalUpdateContact, setOpenModalUpdateContact] = useState(false);
	const [openModalSendSucess, setOpenModalSendSucess] = useState(false);
	const [openModalDetailSerasaResponse, setOpenModalDetailSerasaResponse] = useState(false);

	const handleOpenModalLogsSerasa = () => {
		setOpenModalLogsSerasa(true);
	};

	const handleCloseModalLogsSerasa = () => {
		setOpenModalLogsSerasa(false);
	};

	const handleGetLogsSerasa = (analysisId: string) => {
		setIsLoadingLogsSerasa(true);
		handleOpenModalLogsSerasa();
		serasaService
			.getSerasaByAnalysisId(analysisId)
			.then((result: IMessage<ISerasaData>) => {
				setIsLoadingLogsSerasa(false);

				if (result instanceof Error) {
					handleCloseModalLogsSerasa();

					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;

					toastHandler.present({
						type: "error",
						position: "top-right",
						durationMs: 4000,
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					const { message, description, status, data } = result;

					if (status != 200 && status != 400) {
						toastHandler.present({
							type: "warning",
							position: "top-right",
							durationMs: 4000,
							messageOrContent: <ToastContent title={message as string} content={description as string} />,
						});
					} else {
						const dataSerasaResponse: ISerasaResponse = JSON.parse("" + data?.data);
						setDataSerasaResponse(dataSerasaResponse);

						let income = "";
						const value = dataSerasaResponse.response?.body?.split("RENDA")[1]?.split("|")[0];

						if (value?.includes("PONTUACAO@") && value?.split("PONTUACAO@")[1]?.length) {
							income = value.split("PONTUACAO@")[1];
						}

						const dataSerasa: ISerasaData = {
							...data,
							income,
						};

						setDataSerasa(dataSerasa);
					}
				}
			})
			.catch((err: any) => {
				setIsLoadingLogsSerasa(false);
				handleCloseModalLogsSerasa();

				const { titleMessage, subtitleMessage, contentMessage } = returnMessageError(err);
				toastHandler.present({
					type: "error",
					position: "top-right",
					durationMs: 4000,
					messageOrContent: <ToastContent title={titleMessage} subtitle={subtitleMessage} content={contentMessage} />,
				});
			});
	};

	const handleOpenModalResultAnalysis = () => {
		if (rulesMaxProposal.rules) {
			toastHandler.present({
				type: "error",
				position: "top-right",
				durationMs: 4000,
				messageOrContent: (
					<ToastContent
						title="Alerta!!!"
						subtitle="Análise com impedimento "
						content="Cliente atingiu quantidade máxima de garantias ativas. Entre em contato com suporte Garantti"
					/>
				),
			});
		} else {
			setOpenModalResultAnalysis(true);
		}
	};

	const handleResendInstallment = (id: string, email: string) => {
		FinancialService.sendMailSetup(id, { email }).then((result) => {
			if (result instanceof Error) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={"Erro ao enviar email"} />,
				});
			} else {
				toastHandler.present({
					type: "success",
					position: "top-right",
					messageOrContent: <ToastContent title={"Sucesso"} content={"Email enviado com sucesso !!!"} />,
				});
			}
		})
	};

	const handleOpenModalLogs = () => {
		setOpenModalLogs(true);
	};

	const handleCloseModalLogs = () => {
		setOpenModalLogs(false);
	};

	const handleOpenModalLogsPayload = () => {
		setOpenModalLogsPayload(true);
	};

	const handleCloseModalLogsPayload = () => {
		setOpenModalLogsPayload(false);
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleOpenModalSendSucess = () => {
		setOpenModalSendSucess(true);
	};

	const handleOpenModalUpdate = () => {
		getDetailsAnalysis();
		setOpenModalUpdateContact(true);
		//console.log(prevEmail, prevPhone);
		formRefBiometria.current?.setData({ email: prevEmail, phone: prevPhone });
	};

	const handleOpenModalCancel = () => {
		setOpenModalCancel(true);
	};

	const handleReSendEmail = () => {
		setReSendEmail(true);
	};

	const handleReAnalysis = (id: string) => {
		if (permissionLevel) {
			navigate(`/analises/pedido/${id}`);
		}
	};

	const handleSave = (dados: ISendEmail) => {
		dados.email = email;
		dados.id = id;

		if (!isEmail(email) || prevEmail?.toLocaleLowerCase() !== email?.toLocaleLowerCase()) {
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: "Erro ao reenviar email, pois está inválido. Verifique email cadastrado!",
			});
			return;
		}

		formValidationSchema
			.validate(dados, { abortEarly: false })
			.then((dadosvalidados) => {
				setIsLoading(true);
				analysisService
					.updateEmail({ ...dadosvalidados })
					.then((result) => {
						setIsLoading(false);

						if (result instanceof Error) {
							const titleMessage = "Alerta!!";
							const contentMessage = "Erro: " + result?.message;

							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						} else {
							handleOpenModal();
						}
					})
					.catch((err: any) => {
						setIsLoading(false);
						const { titleMessage, contentMessage } = returnMessageError(err);

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			})
			.catch((errors: yup.ValidationError) => {
				const validationErros: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRef.current?.setErrors(validationErros);
			});
	};

	const handleCancelAnalysis = (dados: ICancelAnalysis) => {
		analysisService.updateCanceledId(id, { ...dados }).then((result) => {
			setIsLoading(false);
		});
	};

	const handleGenerateProposal = (id: string) => {
		analysisService.generateProposal(id).then((result) => {
			setIsLoading(false);

			if (result instanceof Error) {
				alert(result.message);
			} else {
				// alert('erro');
			}
		});
		setOpenModalGenerateProposal(false);
	};

	const handleGetLogs = (analysisId: string) => {
		setIsLoadingLogs(true);
		handleOpenModalLogs();
		analysisService.getLogs(analysisId).then((result) => {
			setIsLoadingLogs(false);
			if (result instanceof Error) {
				setOpenModalLogs(false);
				alert(result.message);
			} else {
				setDataLogs(result);
			}
		});
	};

	const handleOpenModalSerasaResponse = () => {
		setOpenModalDetailSerasaResponse(true);
	};

	const handleGetLogsPayload = (payload?: Object, logId: string = "") => {
		if (isObject(payload)) {
			setPayload(payload);
			setIdLog(logId);
			handleOpenModalLogsPayload();
		} else {
			alert("Não foi encontrado evidência de payload neste log especifico!");
		}
	};

	const getDetailsAnalysis = () => {
		setIsLoading(true);
		analysisService.getDetails(`${id}`).then(async (result) => {
			const analysisRow = result as IDetalheAnalises;
			const biometryRowResult = await analysisService.getBiometryDetails(analysisRow.id as string);

			setIsLoading(false);
			if (result instanceof Error) {
				alert(result.message);
				navigate("/analises");
			} else {
				setBiometryRows(biometryRowResult as BiometryDetails);
				setNome(result.name);
				setProFinally(result.propertyFinally);
				setDoc(result.document);
				setDocLessor(result.lessorCpf);
				setStatus(result.status);
				setRows(result);
				setCreatedFrom(result.createdFrom);
				setPrevEmail(result.email);
				setPrevPhone(maskPhone(result.phone));

				formRefEdit?.current?.setData({
					id: result.id,
					status: result.status,
					obs: result.obs,
					createdFrom: result.createdFrom,
					assuranceMonthAmount: String(result.assuranceMonthAmount),
					rentAmount: String(result.rentAmount),
					lmi: String(result.lmi),
					residentialFee: formatScaleValue(String(result.residentialFee)),
					commercialFee: formatScaleValue(String(result.commercialFee)),
					fee: formatScaleValue(String(result.fee)),
					planlist: JSON.stringify(result.planlist, null, 1),
					updatedAt: result.updated_at,
				});

				setPrevEmail(result.email);
				let baseAssurance = parseFloat(result.rentAmount);

				//if (result.baseCalc == "encargos") {

				//  baseAssurance =
				// setAssuranceBaseCalc( parseFloat(result.rentAmount) +
				//   parseFloat(result.condominiumAmount) +
				//   parseFloat(result.iptuAmount)
				// );
				setChargesBaseCalc(parseFloat(result.rentAmount) + parseFloat(result.condominiumAmount) + parseFloat(result.iptuAmount));
				//  setAssuranceBaseCalc(baseAssurance);
				// }
				// else{
				//   setAssuranceBaseCalc(baseAssurance);
				// }
				setAssuranceBaseCalc(baseAssurance);
				formRefBiometria.current?.setData({
					email: result.email,
					phone: result.phone,
				});

				setRentAmount(parseFloat(result?.rentAmount));
				//alert(rentAmount);
				if (result?.userId) {
					userService.getByID(`${result.userId}`).then((resultUser) => {
						setIsLoading(false);
						if (resultUser instanceof Error) {
							//alert(result.message);
							//navigate('/analises');
						} else {
							setUser(resultUser);
						}
					});
				}
				analysisService.rulesMaxProposal(result.document, result.propertyFinally).then((rules) => {
					setRulesMaxProposal({
						rules: rules?.rules,
						message: rules?.message,
					});
				});

				try {
					const planos = JSON.parse(result.planlist); //regra buscar planos somente que a flag Analysis==1
					const plansAnalysisSelect = planos.filter((rowPlan: IPlan) => rowPlan.flagAnalysis == undefined || rowPlan?.flagAnalysis == "1");
					setPlan(plansAnalysisSelect);
					console.log("537.....", plansAnalysisSelect);
				} catch (_) {
					console.log("Erro ao realizar parse do objeto de planos");
				}
			}
		});
	};

	const handleModalEditSuperClose = () => {
		setOpenModalEditSuper(false);
	};

	const openModalEdit = () => {
		setOpenModalEditSuper(true);
		getDetailsAnalysis();
	};

	const handleUpdateContact = (dados: ISendBiometria) => {
		//dados.id = id
		dados.phone = dados.phone.replace(/[^0-9]/g, "");
		console.log(dados);
		formValidationSchemaBiometria
			.validate(dados, { abortEarly: false })
			.then((dadosvalidados) => {
				setIsLoading(true);
				analysisService.updateSendBiometria(id, { ...dados }).then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						alert(result.message);
					} else {
						handleOpenModalSendSucess();
					}
				});
			})
			.catch((errors: yup.ValidationError) => {
				const validationErros: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRefBiometria.current?.setErrors(validationErros);
			});
	};

	const handleSaveEdit = (dados: IUpdateAnalysisSuperAdmin) => {
		dados.updatedAt = moment.tz("America/Sao_Paulo").toDate();
		dados.assuranceMonthAmount = dados.assuranceMonthAmount ? removeMask(dados.assuranceMonthAmount) : dados.assuranceMonthAmount;
		dados.rentAmount = dados.rentAmount ? removeMask(dados.rentAmount) : dados.rentAmount;
		dados.residentialFee = dados.residentialFee ? removeMask(dados.residentialFee) : dados.residentialFee;
		dados.commercialFee = dados.commercialFee ? removeMask(dados.commercialFee) : dados.commercialFee;
		dados.fee = dados.fee ? removeMask(dados.fee) : dados.fee;
		dados.obs = !dados.obs ? "" : dados.obs;
		dados.planlist = JSON.parse(dados.planlist);

		formEditValidationSchema
			.validate(dados, { abortEarly: false })
			.then((dadosValidados) => {
				setIsLoadingEditSuper(true);
				analysisService
					.updateAnalysisSuperAdmin(dadosValidados)
					.then((result: IMessage<null> | Error) => {
						setIsLoadingEditSuper(false);
						if (result instanceof Error) {
							const titleMessage = "Alerta!!";
							const contentMessage = "Erro: " + result?.message;

							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						} else {
							handleModalEditSuperClose();
							getDetailsAnalysis();

							const { message, description } = result;

							toastHandler.present({
								type: "success",
								position: "top-right",
								messageOrContent: <ToastContent title={message as string} content={description as string} />,
							});
						}
					})
					.catch((err: any) => {
						setIsLoadingEditSuper(false);
						const { titleMessage, contentMessage } = returnMessageError(err);

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			})
			.catch((errors: yup.ValidationError) => {
				setIsLoadingEditSuper(false);
				const validationErros: IVFormErros = {};
				errors?.inner?.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRefEdit?.current?.setErrors(validationErros);
			});
	};

	const ColorTytle = "#1c1b1b";

	useEffect(() => {
		if (id !== "novo") {
			getDetailsAnalysis();
		}
	}, [id]);

	return (
		<LayoutBaseDePagina
			titulo="Análises"
			subtitulo={id === "novo" ? "Nova análise de crédito" : `Alteração de análise [ ${nome} ]`}
			barraDeFerramentas={
				<ToolbarAnalysis
					clickAnalysis={handleOpenModalResultAnalysis}
					showButtonAnalysis={
						createdFrom != "web_service" &&
						status === "issued" &&
						checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.Conclude)
					}
					showButtonProposal={
						status === "awaiting_signature" && isSuperAdmin && checkByProfileGuard(PermissionRolesEnum.WarrantiesOptions, PermissionActionsEnum.Add)
					}
					showButtonEmailresend={
						checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.Conclude) && status === "awaiting_signature"
							? true
							: false
					}
					showButtonCancel={
						checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.Conclude) &&
							(status === "issued" || status == "awaiting_signature" || status === "awaiting_biometria")
							? createdFrom == "web_service"
								? false
								: true
							: false
					}
					showButtonReAnalysis={
						checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.Conclude) &&
							status != "canceled" &&
							status != "hired" &&
							status != "awaiting_signature"
							? createdFrom == "web_service"
								? false
								: true
							: false
					}
					clickCancel={() => handleOpenModalCancel()}
					clickBack={() => navigate("/analises")}
					clickProposal={() => setOpenModalGenerateProposal(true)}
					clickEmailResend={() => setReSendEmail(true)}
					clickReAnalysis={() => SetModalReAnalisys(true)}
					textButtonAnalysis={"Contratar Agora"}
					showButtonUpdateContact={
						checkByProfileGuard(PermissionRolesEnum.AnalysisOptions, PermissionActionsEnum.Conclude) && status === "awaiting_biometria"
							? true
							: false
					}
					clickUpdateContact={() => handleOpenModalUpdate()}
				/>
			}
		>
			{openModalUpdateContact && (
				<ModalContext
					isCloseModal={() => setOpenModalUpdateContact(false)}
					isOpenModal={openModalUpdateContact}
					messageType={"alert"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					onclickConfirm={() => {
						// handleUpdateContact();
					}}
					ITitle={"Reenviar Link de biometria"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formBiometria"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRefBiometria}
									onSubmit={handleUpdateContact}
								>
									<Grid container item direction="column" padding={1} spacing={2}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}

										<Grid container item direction="row" spacing={2}>
											<Grid item xs={8}>
												<VTextField fullWidth label="Email" name="email" disabled={isLoading}></VTextField>
											</Grid>
											<Grid item xs={4}>
												<VMaskTextField fullWidth label="Telefone" name="phone" disabled={isLoading} mask="phone"></VMaskTextField>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										container
										item
										sm={12}
										sx={{
											border: "none",
											display: "flex",
											justifyContent: "center",
										}}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										<Typography variant="h6" sx={{ fontWeight: "bold" }} color={"primary"}>
											IMPORTANTE
										</Typography>
										<Box sx={{ background: "#7924c7", padding: 2, borderRadius: 4, border: "1px solid #8c45ff" }}>
											<Typography color={"#fff"}>
												O E-mail e o Telefone informado deverão ser obrigatoriamente do locatário. Caso seja verificado, posteriormente,
												que o telefone e o e-mail informados não são do locatário, a GARANTTI poderá cancelar a garantia e recusar o
												pagamento de eventual sinistro.
											</Typography>
										</Box>

										<Button
											color="primary"
											disableElevation
											variant={"outlined"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={() => {
												setOpenModalUpdateContact(false);
											}}
										>
											{"Voltar"}
										</Button>
										<Button
											color="primary"
											disableElevation
											variant={"contained"}
											startIcon={<Icon> check</Icon>}
											onClick={() => {
												formRefBiometria.current?.submitForm();
												setOpenModalUpdateContact(false);
											}}
										>
											{"Reenviar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{loggedUser?.role == "admin" && isSuperAdmin && (
				<ModalContext
					isCloseModal={handleModalEditSuperClose}
					isOpenModal={openModalEditSuper}
					messageType={"info"}
					enableBackdropClose={false}
					overrideWidthValue="80"
					showButtonCancel={false}
					showTitleIcon={false}
					stylePropert={{ display: "contents" }}
					ITitle={"Alteração de Análise"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<VForm
								id="formId"
								placeholder="Your placeholder"
								onPointerEnterCapture={() => console.log("Pointer entered")}
								onPointerLeaveCapture={() => console.log("Pointer left")}
								style={{ padding: "0px 50px" }}
								ref={formRefEdit}
								onSubmit={handleSaveEdit}
							>
								<Box margin={1} component={Paper} border={"none"} variant="outlined">
									<Grid
										container
										item
										direction="row"
										spacing={2}
										sx={{
											marginLeft: "5px",
											marginTop: "5px",
											marginBottom: "15px",
											paddingRight: "40px",
										}}
									>
										<Grid item xs={12} md={4}>
											<VTextField fullWidth label="Status" name="status" disabled={isLoadingEditSuper} select>
												<MenuItem value="pre_approved">Pré-Aprovada</MenuItem>
												<MenuItem value="awaiting_biometria">Pendente biometria</MenuItem>
												<MenuItem value="awaiting_signature">Assinatura Pendente</MenuItem>
												<MenuItem value="in_progress">Em Andamento</MenuItem>
												<MenuItem value="issued">Aprovada</MenuItem>
												<MenuItem value="pending">Pendente</MenuItem>
												<MenuItem value="expired">Expirada</MenuItem>
												<MenuItem value="refused">Recusada</MenuItem>
												<MenuItem value="canceled">Cancelada</MenuItem>
												<MenuItem value="hired">Contratada</MenuItem>
											</VTextField>
										</Grid>
										<Grid item xs={12} md={6}>
											<VTextField fullWidth label="Observações" name="obs" disabled={isLoadingEditSuper} />
										</Grid>
										<Grid item xs={12} md={2}>
											<VTextField fullWidth label="Criado de" name="createdFrom" disabled={isLoadingEditSuper} />
										</Grid>
									</Grid>
									<Grid
										container
										item
										sx={{
											marginLeft: "5px",
											marginBottom: "15px",
											paddingRight: "40px",
										}}
										direction="row"
										spacing={2}
									>
										<Grid item xs={12} md={2}>
											<VTextField fullWidth label="LMI" name="lmi" disabled={isLoadingEditSuper} />
										</Grid>
										<Grid item xs={12} md={2}>
											<VMaskTextField
												fullWidth
												label="Valor da parcela"
												name="assuranceMonthAmount"
												disabled={isLoadingEditSuper}
												mask="real"
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<VMaskTextField fullWidth label="Valor do aluguel" name="rentAmount" disabled={isLoadingEditSuper} mask="real" />
										</Grid>
										<Grid item xs={12} md={2}>
											<VMaskTextField
												fullWidth
												label="Taxa do residencial"
												name="residentialFee"
												disabled={isLoadingEditSuper}
												mask="real"
												InputProps={{
													startAdornment: <InputAdornment position="start"></InputAdornment>,
												}}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<VMaskTextField
												fullWidth
												label="Taxa do comercial"
												name="commercialFee"
												disabled={isLoadingEditSuper}
												mask="real"
												InputProps={{
													startAdornment: <InputAdornment position="start"></InputAdornment>,
												}}
											/>
										</Grid>
										<Grid item xs={12} md={2}>
											<VMaskTextField
												fullWidth
												label="Taxa (fee)"
												name="fee"
												disabled={isLoadingEditSuper}
												mask="real"
												InputProps={{
													startAdornment: <InputAdornment position="start"></InputAdornment>,
												}}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										item
										sx={{
											marginLeft: "5px",
											marginBottom: "15px",
											paddingRight: "40px",
										}}
										direction="row"
										spacing={2}
									>
										<Grid item xs={12}>
											<div>
												<pre>
													<VTextField
														fullWidth
														name="planlist"
														disabled={isLoadingEditSuper}
														sx={{ resize: "none" }}
														maxRows={6}
														label="PlanList"
														multiline={true}
														size="medium"
														rows={6}
													/>
												</pre>
											</div>
										</Grid>
									</Grid>
									<Grid
										container
										item
										direction="row"
										sx={{
											visibility: "hidden",
											margin: 0,
											padding: 0,
											height: "10px",
										}}
									>
										<Grid item xs={12} md={3}>
											<VTextField fullWidth name="updatedAt" type="date" />
										</Grid>
										<Grid item xs={12} md={3}>
											<VTextField fullWidth name="id" />
										</Grid>
									</Grid>
									<Typography
										sx={{
											mt: 2,
											mr: 2,
											mb: 2,
											textAlign: "end",
										}}
									>
										{isLoadingEditSuper ? (
											<CircularProgress style={{ height: "20px", marginRight: "40px" }} variant="indeterminate" />
										) : (
											<Button
												disableElevation
												color="primary"
												variant={"contained"}
												startIcon={<SaveIcon />}
												onClick={() => formRefEdit?.current?.submitForm()}
												sx={{ mr: 2 }}
											>
												Salvar
											</Button>
										)}
										<Button
											color="primary"
											disableElevation
											variant={"outlined"}
											startIcon={<CloseIcon />}
											onClick={handleModalEditSuperClose}
										>
											Sair
										</Button>
									</Typography>
								</Box>
							</VForm>
						</Box>,
					]}
				/>
			)}

			{openModal && (
				<BasicModal
					isCloseModal={() => setOpenModal(false)}
					isOpenModal={openModal}
					messageType={"success"}
					showButtonCancel={false}
					textButtonConfirm={"OK"}
					onclickConfirm={() => {
						setOpenModal(false), navigate("/analises");
					}}
					IMessage={"Contratação realizada com sucesso !"}
				/>
			)}

			{openModalSendSucess && (
				<BasicModal
					isCloseModal={() => setOpenModalSendSucess(false)}
					isOpenModal={openModalSendSucess}
					messageType={"success"}
					showButtonCancel={false}
					textButtonConfirm={"OK"}
					onclickConfirm={() => {
						setOpenModalSendSucess(false);
					}}
					IMessage={"Link enviado com sucesso !"}
				/>
			)}

			{openModalResultAnalysis && status != "pre_approved" && (
				<ModalResultAnalysis
					isCloseModal={() => setOpenModalResultAnalysis(false)}
					isOpenModal={openModalResultAnalysis}
					messageType={"success"}
					showButtonCancel={false}
					overrideWidthValue="70%"
					textButtonConfirm={"Contratar depois"}
					onclickConfirm={() => {
						handleOpenModalResultAnalysis();
					}}
					ITitle={"Análise Aprovada"}
					IMessage={[
						<Grid
							container
							item
							sx={{ textAlign: "center", justifyContent: "center" }}
							display={"flex"}
							flexDirection={smDown ? "column" : "row"}
							gap={2}
						>
							{plan?.map((row: any) => {
								return (
									<Grid
										container
										item
										xs={12}
										md={4}
										margin={1}
										sx={{
											border: "none",
											display: "flex",
											justifyContent: "center",
										}}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										key={row.id}
										padding={2}
									>
										<Box
											sx={{
												width: "100%",
												bgcolor: "background.paper",
												border: "1px solid #eaecee",
												borderRadius: "80px 2px 80px 2px",
												padding: 4,
												boxShadow: "rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
											}}
										>
											<Typography variant="h5" sx={{ color: "text.secondary" }}>
												{row && row?.planId.includes("__charges") ? row.plan.description + " +" : row?.plan?.description + ""}
											</Typography>
											<Typography
												variant="h5"
												sx={{
													color: "#7924c7 ",
													fontWeight: 700,
													fontFamily: "arial",
												}}
											>
												{propFinally === "residential"
													? maskReal(
														(
															(row?.planId.includes("__charges") ? chargesBaseCalc : assuranceBaseCalc) *
															(row.residentialFee / 100.0)
														).toFixed(2)
													)
													: maskReal(
														(
															(row?.planId.includes("__charges") ? chargesBaseCalc : assuranceBaseCalc) *
															(row.commercialFee / 100.0)
														).toFixed(2)
													)}
												<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
													/mes
												</Typography>
											</Typography>
											<Button
												color="primary"
												disableElevation
												variant={"contained"}
												fullWidth
												startIcon={<Icon> checkIcon</Icon>}
												onClick={() =>
													navigate(
														`/analises/contratacao?analise=${rows?.id}&plano=${row?.planId}` //${row.id}
													)
												}
												sx={{ mt: 1, mb: 1 }}
											>
												CONTRATAR
											</Button>
											<Divider />
											<nav aria-label="secondary mailbox folders">
												<List>
													<ListItem
														sx={{
															textAlign: "center",
															justifyContent: "center",
															display: "flex",
															flexDirection: "column",
															padding: "1px",
														}}
													>
														<Typography variant="caption">Taxa Garantti.</Typography>
														<Typography variant="subtitle1">
															{propFinally === "residential" ? row.residentialFee : row.commercialFee} %
															{/* {row.commercialFee} % */}
														</Typography>
													</ListItem>
													<ListItem
														sx={{
															textAlign: "center",
															justifyContent: "center",
															padding: "1px",
														}}
													>
														<Typography variant="subtitle1">LMI {row.lmi} x</Typography>
													</ListItem>
													<ListItem
														sx={{
															textAlign: "center",
															justifyContent: "center",
															display: "flex",
															flexDirection: "column",
															padding: "1px",
														}}
													>
														<Typography variant="caption">Valor LMI</Typography>
														<Typography variant="subtitle1">
															{(
																(row?.planId.includes("__charges") ? chargesBaseCalc : assuranceBaseCalc) * parseInt(row.lmi)
															).toLocaleString("pt-br", {
																style: "currency",
																currency: "BRL",
															})}
														</Typography>
														<Typography sx={{ color: "#7924c7 ", fontWeight: 400, fontFamily: "arial" }}>
															{row?.planId.includes("__charges") ? " (aluguel + encargos)" : "( aluguel )"}
														</Typography>
													</ListItem>
													<ListItem sx={{ justifyContent: "left", padding: "1px" }}>
														<Typography variant="subtitle1" sx={{ color: "#39c463" }}>
															<CheckIcon />
														</Typography>
														<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
															Sem fiador ou caução.
														</Typography>
													</ListItem>
													<ListItem sx={{ justifyContent: "left", padding: "1px" }}>
														<Typography variant="subtitle1" sx={{ color: "#39c463" }}>
															<CheckIcon />
														</Typography>
														<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
															Assessoria Jurídica gratuita
														</Typography>
													</ListItem>
													<ListItem sx={{ justifyContent: "left", padding: "1px" }}>
														<Typography variant="subtitle1" sx={{ color: "#39c463" }}>
															<CheckIcon />
														</Typography>
														<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
															Contratação 100% digital
														</Typography>
													</ListItem>
													<ListItem sx={{ justifyContent: "left", padding: "1px" }}>
														<Typography variant="subtitle1" sx={{ color: "#39c463" }}>
															<CheckIcon />
														</Typography>
														<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
															Recebimento Inadimplência
														</Typography>
													</ListItem>
													<ListItem sx={{ justifyContent: "left", padding: "1px" }}>
														<Typography variant="subtitle1" sx={{ color: "#39c463" }}>
															<CheckIcon />
														</Typography>
														<Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
															Limite de saída{" "}
															{(
																("" + row?.planId.includes("__charges") ? chargesBaseCalc : assuranceBaseCalc) *
																parseInt(row.plan.coverage ? row.plan.coverage : "0")
															).toLocaleString("pt-br", {
																style: "currency",
																currency: "BRL",
															})}
															{" absorvido do LMI"}
															{row.coverage}
														</Typography>
													</ListItem>
												</List>
											</nav>
										</Box>
									</Grid>
								);
							})}

							<Grid
								container
								item
								sm={12}
								sx={{
									border: "none",
									display: "flex",
									justifyContent: "center",
								}}
								display={"flex"}
								flexDirection={smDown ? "column" : "row"}
								padding={2}
							>
								<Button
									color="primary"
									disableElevation
									variant={"outlined"}
									startIcon={<Icon> checkIcon</Icon>}
									onClick={() => setOpenModalResultAnalysis(false)}
								>
									{status === "issued" ? "Contratar depois" : "OK"}
								</Button>
							</Grid>
						</Grid>,
					]}
				/>
			)}

			{openModalResultAnalysis && status === "pre_approved" && (
				<ModalContext
					isCloseModal={() => setOpenModalResultAnalysis(false)}
					isOpenModal={openModalResultAnalysis}
					messageType={"pre_success"}
					showButtonCancel={false}
					overrideWidthValue="33%"
					textButtonConfirm={"Cancelar"}
					ITitle={"Análise Pré-Aprovada "}
					IMessage={[
						<Box>
							<Grid
								item
								sx={{
									width: "100%",
									padding: "0.2% 2.3%",
									borderRadius: "4px",
								}}
							>
								<Box display={"flex"} gap={1} alignItems={"center"}>
									<Typography variant="body1" sx={{ color: "#2b94e2" }}>
										Analise Pré-Aprovada com valor de até
									</Typography>
									<Typography
										variant="body1"
										sx={{
											color: "#2b94e2",
											fontWeight: "600",
											fontSize: "18px",
										}}
									>
										{parseFloat(`${rows?.creditLimit}`).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
									</Typography>
								</Box>
								<Typography variant="subtitle2" sx={{ color: "#2b94e2" }}>
									Próximos passos: você deverá reduzir o valor do aluguel e taxas e ou negociar junto ao proprietário ou ainda buscar outro
									imóvel que possua os valores e taxas compatíveis com este valor total pré-aprovado.
								</Typography>
							</Grid>
							<Grid
								container
								item
								sm={12}
								sx={{
									border: "none",
									display: "flex",
									justifyContent: "center",
									mt: 2,
								}}
								display={"flex"}
								flexDirection={smDown ? "column" : "row"}
								padding={2}
								gap={2}
							>
								<Button
									color="primary"
									disableElevation
									variant={"contained"}
									startIcon={<Icon> checkIcon</Icon>}
									onClick={() => {
										handleReAnalysis(id);
										setOpenModalResultAnalysis(false);
									}}
								>
									{"Contratar"}
								</Button>
								<Button
									color="primary"
									disableElevation
									variant={"outlined"}
									startIcon={<Icon> arrow_back_Ico</Icon>}
									onClick={() => {
										setOpenModalResultAnalysis(false);
									}}
								>
									{"Voltar"}
								</Button>
							</Grid>
						</Box>,
					]}
				/>
			)}

			{openModalCancel && (
				<ModalContext
					isCloseModal={() => setOpenModalCancel(false)}
					isOpenModal={openModalCancel}
					messageType={"alert"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					onclickConfirm={() => {
						handleOpenModalCancel();
					}}
					ITitle={"Deseja cancelar a análise de crédito ?"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdCancel"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handleCancelAnalysis}
								>
									<Grid container item direction="column" padding={1} spacing={2}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField
													fullWidth
													label="Motivo do cancelamento"
													name="canceledType"
													disabled={isLoading}
													defaultValue="Outros"
													select
													onChange={(e) => setReasonCancel(e.target.value)}
												>
													{/* <MenuItem value={'Desistência da Locação'}>Desistência da Locação</MenuItem>
                                                    <MenuItem value={'Optou por caução ou fiador'}>Optou por caução ou fiador</MenuItem>
                                                    <MenuItem value={'Optou por outra garantia/seguro'}>Optou por outra garantia/seguro</MenuItem>
                                                    <MenuItem value={'Mudou de imobiliária/imóvel'}>Mudou de imobiliária/imóvel</MenuItem>
                                                    <MenuItem value={'Preço'}>Preço</MenuItem>
                                                    <MenuItem value={'Duplicidade de análise'}>Duplicidade de análise</MenuItem>
                                                    <MenuItem value={'Outros'}>Outros</MenuItem>
                                                     */}
													<MenuItem value={"Preço"}>Preço</MenuItem>
													<MenuItem value={"Compra do imóvel"}>Compra do imóvel</MenuItem>
													<MenuItem value={"Desistência da locação"}>Desistência da locação</MenuItem>
													<MenuItem value={"Distrato locador e imobiliária"}>Distrato locador e imobiliária</MenuItem>
													<MenuItem value={"Duplicidade de garantias"}>Duplicidade de garantias</MenuItem>
													<MenuItem value={"Garantia irregular/fraude"}>Garantia irregular/fraude</MenuItem>
													<MenuItem value={"Imissão na posse - Sinistro"}>Imissão na posse - Sinistro</MenuItem>
													<MenuItem value={"Inadimplência (imobiliária)"}>Inadimplência (imobiliária)</MenuItem>
													<MenuItem value={"LMI/LMG atingido"}>LMI/LMG atingido</MenuItem>
													<MenuItem value={"Termo de entrega de chaves - Sinistro"}>Termo de entrega de chaves - Sinistro</MenuItem>
													<MenuItem value={"Termo de entrega de chaves e confissão de dívidas"}>
														Termo de entrega de chaves e confissão de dívidas
													</MenuItem>
													<MenuItem value={"Termo de entrega de chaves e inexistência de débitos - imobiliária"}>
														Termo de entrega de chaves e inexistência de débitos - imobiliária
													</MenuItem>
													<MenuItem value={"Termo de revogação/declaração de responsabilidade"}>
														Termo de revogação/declaração de responsabilidade
													</MenuItem>
													<MenuItem value={"Troca de garantia"}>Troca de garantia</MenuItem>
													<MenuItem value={"Troca de imobiliária/estipulante/administradora"}>
														Troca de imobiliária/estipulante/administradora
													</MenuItem>
													<MenuItem value={"Troca de locatário"}>Troca de locatário</MenuItem>
													<MenuItem value={"Troca de seguradora"}>Troca de seguradora</MenuItem>
													<MenuItem value={"Outros"}>Outros</MenuItem>
												</VTextField>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField
													fullWidth
													label="Descreva o motivo"
													name="canceledReason"
													disabled={isLoading}
													multiline
													rows={4}
												></VTextField>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										container
										item
										sm={12}
										sx={{
											border: "none",
											display: "flex",
											justifyContent: "center",
										}}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										{reasonCancel != "" ? (
											<Button
												color="primary"
												disableElevation
												variant={"contained"}
												startIcon={<Icon> check</Icon>}
												onClick={() => {
													formRef.current?.submitForm();
													navigate("/analises/");
												}}
											>
												{"Cancelar análise"}
											</Button>
										) : (
											""
										)}
										<Button
											color="primary"
											disableElevation
											variant={"outlined"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={() => {
												setOpenModalCancel(false);
											}}
										>
											{"Voltar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{modalReAnalysis && (
				<ModalContext
					isCloseModal={() => setOpenModalResultAnalysis(false)}
					isOpenModal={modalReAnalysis}
					messageType={"question"}
					showButtonCancel={false}
					overrideWidthValue="36%"
					textButtonConfirm={"Cancelar"}
					ITitle={"Deseja fazer a Reanálise  ?"}
					IMessage={[
						<Grid
							container
							item
							sm={12}
							sx={{ border: "none", display: "flex", justifyContent: "center" }}
							display={"flex"}
							flexDirection={smDown ? "column" : "row"}
							padding={2}
							gap={2}
						>
							<Button
								color="primary"
								disableElevation
								variant={"contained"}
								startIcon={<Icon> checkIcon</Icon>}
								onClick={() => {
									handleReAnalysis(id);
									SetModalReAnalisys(false);
								}}
							>
								{"Reanalisar"}
							</Button>
							<Button
								color="primary"
								disableElevation
								variant={"outlined"}
								startIcon={<Icon> arrow_back_Ico</Icon>}
								onClick={() => {
									SetModalReAnalisys(false);
								}}
							>
								{"Voltar"}
							</Button>
						</Grid>,
					]}
				/>
			)}

			{openModalGenerateProposal && (
				<ModalContext
					isCloseModal={() => setOpenModalGenerateProposal(false)}
					isOpenModal={openModalGenerateProposal}
					messageType={"alert"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					onclickConfirm={() => {
						handleGenerateProposal(id);
					}}
					ITitle={"Criação de garantia "}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d", textAlign: "center" }}>
								Confirma a criação de uma garantia sem Assinatura de contrato ?
							</Typography>
							{/* <Typography>
                            {`${id}`}
                          </Typography> */}
							<Grid
								container
								item
								sm={12}
								sx={{
									border: "none",
									display: "flex",
									justifyContent: "center",
								}}
								display={"flex"}
								flexDirection={smDown ? "column" : "row"}
								padding={2}
								gap={2}
							>
								<Button
									color="info"
									disableElevation
									variant={"contained"}
									startIcon={<Icon> checkIcon</Icon>}
									onClick={() => {
										handleGenerateProposal(id);
										navigate("/garantias/");
									}}
								>
									{"Gerar Garantia"}
								</Button>
								<Button
									color="info"
									disableElevation
									variant={"outlined"}
									startIcon={<Icon> arrow_back_Ico</Icon>}
									onClick={() => {
										setOpenModalGenerateProposal(false);
									}}
								>
									{"Voltar"}
								</Button>
							</Grid>
						</Box>,
					]}
				/>
			)}

			{reSendEmail && (
				<ModalContext
					isCloseModal={() => setReSendEmail(false)}
					isOpenModal={reSendEmail}
					messageType={"alert"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					onclickConfirm={() => { }}
					ITitle={"Confirmação de email"}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									id="formIdSave"
									placeholder="Your placeholder"
									onPointerEnterCapture={() => console.log("Pointer entered")}
									onPointerLeaveCapture={() => console.log("Pointer left")}
									ref={formRef}
									onSubmit={handleSave}
								>
									<Grid container item direction="column" padding={1} spacing={2}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}
										<Grid container item direction="row" spacing={1} sx={{ mb: 2 }}>
											<Grid item xs={4} sx={{ textAlign: "right" }}>
												Confirme o email cadastrado :
											</Grid>
											<Grid item xs={6} sx={{ alignItems: "left" }}>
												<Typography variant="inherit" sx={{ fontWeight: "bold" }}>
													{prevEmail}
												</Typography>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField
													fullWidth
													label="Confirma o email do locatário"
													name="email"
													//placeholder={prevEmail}
													trimEmptySpaces={true}
													disabled={isLoading}
													onChange={(e) => setEmail(e.target.value)}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton aria-label="toggle password visibility" edge="end">
																	<EmailOutlined />
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											</Grid>
										</Grid>
									</Grid>

									<Grid
										container
										item
										sm={12}
										sx={{
											border: "none",
											display: "flex",
											justifyContent: "center",
										}}
										display={"flex"}
										flexDirection={smDown ? "column" : "row"}
										padding={2}
										gap={2}
									>
										<Button
											color="info"
											disableElevation
											variant={"contained"}
											startIcon={<Icon> checkIcon</Icon>}
											onClick={() => {
												formRef.current?.submitForm();
											}}
										>
											{"Confirmar"}
										</Button>
										<Button
											color="info"
											disableElevation
											variant={"outlined"}
											startIcon={<Icon> arrow_back_Ico</Icon>}
											onClick={() => {
												setReSendEmail(false);
											}}
										>
											{"Voltar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{openModalSerasaResponse && rows && (
				<ModalContext
					isCloseModal={() => setOpenModalSerasaResponse(false)}
					isOpenModal={openModalSerasaResponse}
					messageType={"question"}
					showButtonCancel={false}
					showButtonConfirm={false}
					showTitleIcon={false}
					textButtonCancel="Fechar"
					onclickConfirm={() => { }}
					enableBackdropClose={true}
					overrideWidthValue={"60%"}
					ITitle={"Resposta do Serasa"}
					IMessage={[<SerasaResponseRejectedModalContent analysis={rows} />]}
				/>
			)}

			<Grid container spacing={2} sx={{ width: "100%", maxWidth: "1300px", mt: 1, ml: 1, gap: 1.5 }}>
				<DetailAnalisys
					canceledReason={`${rows?.canceledReason}`}
					expiresAt={`${rows?.expiresAt}`}
					biometryStatus={biometryRows?.status}
					biometryUpdatedAt={biometryRows?.updatedAt == null ? "--/--/----" : format(biometryRows?.updatedAt, "dd/MM/yyyy")}
					showButtonStatus={true}
					termBegin={`${rows?.termBegin}`}
					IPTUAmount={`${rows?.iptuAmount}`}
					addressCity={`${rows?.addressCity}`}
					addressComplement={`${rows?.addressComplement}`}
					addressDistrict={`${rows?.addressDistrict}`}
					addressNumber={`${rows?.addressNumber}`}
					addressState={`${rows?.addressState}`}
					email={`${rows?.email}`}
					addressStreet={`${rows?.addressStreet}`}
					addressZip={`${rows?.addressZipcode}`}
					canceledType={`${rows?.canceledType}`}
					clientName={`${rows?.client?.name}`}
					condominiumAmount={`${rows?.condominiumAmount}`}
					createAt={`${rows?.createdAt}`}
					eletrictAmount={`${rows?.electricEnergyAmount}`}
					gasAmount={`${rows?.gasAmount}`}
					waterAmount={`${rows?.waterAmount}`}
					rentAmount={`${rows?.rentAmount}`}
					onclickbuttonStatus={openModalEdit}
					onclickbuttonResend={handleResendInstallment}
					status={`${rows?.status}`}
					origin={`${rows?.createdFrom}`}
					propertyOccupation={`${rows?.propertyFinally}`}
					propertyType={`${rows?.propertyType}`}
					vigencyAmount={`${rows?.rentMonthsCount}`}
					limitSerasa={`${rows?.creditLimit}`}
					dataLoading={isLoading}
					baseCalc={`${rows?.baseCalc}`}
					planList={plan}
					transaction={rows?.transaction}
					installmentSetup={rows?.installmentSetup}
				/>
				<DetailTenant
					document={`${rows?.document}`}
					name={`${rows?.name}`}
					birthDate={`${rows?.birthDate}`}
					mother={`${rows?.motherName}`}
					email={`${rows?.email}`}
					maritalStatus={`${rows?.maritalStatus}`}
					mobilePhone={`${rows?.mobilePhone}`}
					phone={`${rows?.phone}`}
					profession={`${rows?.employmentRelationShip}`}
					dataLoading={isLoading}
				/>

				<DetailLocator
					document={`${rows?.lessorCpf || rows?.lessor_cnpj}`}
					name={`${rows?.lessorName}`}
					birthDate={`${rows?.lessorBirthDate}`}
					obs={`${rows?.obs}`}
					email={`${rows?.lessorEmail}`}
					lessorTypePerson={`${rows?.lessorTypePerson}`}
					mobilePhone={`${rows?.lessorMobilePhone}`}
					phone={`${rows?.lessorPhone}`}
					dataLoading={isLoading}
				/>
				<DetailUser
					name={user?.name}
					email={user?.email}
					updateAt={
						rows?.updated_at ? formatDate(new Date(rows.updated_at)) : rows?.createdAt ? formatDate(new Date(rows.createdAt)) : "Não informada"
					}
					dataLoading={isLoading}
				/>

				{/* Logs da Análise e Serasa  */}
				{loggedUser?.role == "admin" && isSuperAdmin && (
					<Grid container component={Paper} sx={{ width: "100%", maxWidth: "1300px", padding: 2 }}>
						<Grid container item direction="row" spacing={1}>
							<Grid item xs={isSuperAdmin ? 7 : 8}>
								<Typography variant="h6" color={ColorTytle}>
									Logs
								</Typography>
							</Grid>
						</Grid>

						<Grid container item xs={12} display={"flex"} flexDirection={"row"} mt={2} ml={2} mb={1}>
							<Grid container item xs={12} direction="row" spacing={1} gap={1}>
								<Box
									display={"flex"}
									sx={{
										boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
										padding: 2,
									}}
									width={"100%"}
									gap={2}
								>
									<Typography variant="h6" sx={{ fontWeight: 400 }}>
										Logs da Análise
									</Typography>
									<Button
										color="primary"
										disableElevation
										variant={"outlined"}
										startIcon={<RemoveRedEyeOutlinedIcon />}
										onClick={() => handleGetLogs(id)}
									>
										Visualizar
									</Button>
								</Box>

								<Box
									display={"flex"}
									sx={{
										boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
										padding: 2,
										alignItems: "center",
									}}
									width={"100%"}
									gap={2}
								>
									<Typography variant="h6" sx={{ fontWeight: 400 }}>
										Logs Serasa
									</Typography>
									<Button
										color="primary"
										disableElevation
										variant={"outlined"}
										startIcon={<RemoveRedEyeOutlinedIcon />}
										onClick={() => handleGetLogsSerasa(id)}
									>
										Visualizar
									</Button>
									{rows?.sendSerasa == "send_serasa" ? (
										<Box display={"flex"} gap={1} pl={2}>
											<Icon sx={{ color: "#55c355" }}>send_outlined_icon</Icon>
											<Typography
												variant="subtitle1"
												sx={{
													fontWeight: 400,
													color: "#55c355",
													alignContent: "center",
												}}
											>
												Análise enviada para o Serasa
											</Typography>
										</Box>
									) : (
										<Box display={"flex"} gap={1} pl={2}>
											<Icon sx={{ color: "#c12c00" }}>cancel_schedule_send_outlined_icon</Icon>
											<Typography variant="subtitle1" sx={{ fontWeight: 400, color: "#c12c00" }}>
												Análise não enviada para o Serasa
											</Typography>
										</Box>
									)}
								</Box>
								{rows?.reasonId && (
									<Box
										display={"flex"}
										sx={{
											boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
											padding: 2,
											alignItems: "center",
										}}
										width={"100%"}
										gap={2}
									>
										<Typography variant="h6" sx={{ fontWeight: 400 }}>
											Motivo Recusa de Análise
										</Typography>
										<Typography variant="subtitle1" sx={{ fontWeight: 400, color: "#c12c00" }}>
											{rows?.reasonId}
										</Typography>
									</Box>
								)}

								{isSuperAdmin && (
									<Box
										display={"flex"}
										sx={{
											boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
											padding: 2,
										}}
										width={"100%"}
										gap={2}
									>
										<Typography variant="h6" sx={{ fontWeight: 400 }}>
											Detalhes do Crédito
										</Typography>
										<Button
											color="primary"
											disableElevation
											variant={"outlined"}
											startIcon={<RemoveRedEyeOutlinedIcon />}
											onClick={() => handleOpenModalSerasaResponse()}
										>
											Visualizar
										</Button>
									</Box>
								)}
							</Grid>
						</Grid>
					</Grid>
				)}
			</Grid>

			{/* Modal Visualização do log do Serasa */}
			{loggedUser?.role == "admin" && (
				<ModalContext
					isCloseModal={handleCloseModalLogsSerasa}
					isOpenModal={openModalLogsSerasa}
					messageType={"info"}
					enableBackdropClose={false}
					overrideWidthValue="100"
					showButtonCancel={false}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography mb={1}>
								<Typography
									sx={{
										color: "#000000",
										display: "inline-flex",
										fontSize: "20px",
										fontWeight: "bold",
									}}
								>
									<DescriptionOutlinedIcon
										sx={{
											fontSize: "35px",
											marginRight: "15px",
										}}
									/>
									Log do Serasa
								</Typography>
							</Typography>

							{isLoadingLogsSerasa ? (
								<CircularProgress />
							) : (
								<>
									<Grid container item direction="row" spacing={1} mb={1.5}>
										<Grid container item direction="row" spacing={1}>
											<Grid item xs={12} md={8}>
												<Typography variant="caption" color={"text.secondary"}>
													Razão
												</Typography>
												<Typography variant="subtitle2">{rows?.reasonId || "Não fornecida"}</Typography>
											</Grid>
											<Grid item xs={12} md={4}>
												<Typography variant="caption" color={"text.secondary"}>
													Status do Serasa
												</Typography>
												<Typography variant="subtitle2">
													{convertStatusSerasa(dataSerasaResponse?.status) || "Não fornecida"}
												</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid container item direction="row" spacing={1} mb={1.5}>
										<Grid container item direction="row" spacing={1}>
											<Grid item xs={12} sx={{ backgroundColor: "#f0f1f1" }}>
												<Typography variant="caption" color={"text.secondary"}>
													Erro Fornecido Pelo Serasa
												</Typography>
												<Typography variant="subtitle2">{dataSerasaResponse?.serasa_response?.error || "Não fornecido"}</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid container item direction="row" spacing={1} mb={1.5}>
										<Grid container item direction="row" spacing={1}>
											<Grid item xs={12} md={4}>
												<Typography variant="caption" color={"text.secondary"}>
													Mensagem do Serasa
												</Typography>
												<Typography variant="subtitle2">{dataSerasaResponse?.serasa_response?.message || "Não fornecida"}</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid container item direction="row" spacing={1} mb={1.5}>
										<Grid container item direction="row" spacing={1}>
											<Grid item xs={12} md={8} sx={{ backgroundColor: "#f0f1f1" }}>
												<Typography variant="caption" color={"text.secondary"}>
													Renda do Serasa
												</Typography>
												<Typography variant="subtitle2">{dataSerasa?.income || "Não fornecida"}</Typography>
											</Grid>
											<Grid item xs={12} md={4} sx={{ backgroundColor: "#f0f1f1" }}>
												<Typography variant="caption" color={"text.secondary"}>
													Score do Serasa
												</Typography>
												<Typography variant="subtitle2">{dataSerasa?.naturalPersonSerasaScore || "Não fornecida"}</Typography>
											</Grid>
										</Grid>
									</Grid>

									<Grid container item direction="row" spacing={1} mb={1.5}>
										<Grid item xs={12}>
											<Grid item xs={12}>
												<Typography variant="caption" color={"text.secondary"}>
													Descrição da Mensagem do Serasa
												</Typography>
												<Typography variant="subtitle2">
													{dataSerasaResponse?.serasa_response?.message_description || "Não fornecido"}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid container item direction="row" spacing={1} mb={1.5}>
										<Grid item xs={12} sx={{ backgroundColor: "#f0f1f1" }}>
											<Grid item xs={12}>
												<Typography variant="caption" color={"text.secondary"}>
													Observação:
												</Typography>
												<Typography variant="subtitle2">{rows?.obs || "Não fornecido"}</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid
										container
										item
										sx={{
											border: "none",
											display: "inline-flex",
										}}
									>
										<div>
											<pre>{JSON.stringify(dataSerasaResponse, null, 1)}</pre>
										</div>
									</Grid>
								</>
							)}

							<Typography
								sx={{
									mt: 5,
									textAlign: "end",
								}}
							>
								<Button
									style={{ backgroundColor: "red", color: "white" }}
									disableElevation
									variant={"contained"}
									startIcon={<CloseIcon />}
									onClick={handleCloseModalLogsSerasa}
								>
									{"Sair"}
								</Button>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{/* Modal Visualização dos logs */}
			{loggedUser?.role == "admin" && (
				<ModalContext
					isCloseModal={handleCloseModalLogs}
					isOpenModal={openModalLogs}
					messageType={"info"}
					enableBackdropClose={false}
					overrideWidthValue="100"
					showButtonCancel={false}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography>
								<Typography
									sx={{
										color: "#000000",
										display: "inline-flex",
										fontSize: "20px",
										fontWeight: "bold",
									}}
								>
									<DescriptionOutlinedIcon
										sx={{
											fontSize: "35px",
											marginRight: "15px",
										}}
									/>
									Logs
								</Typography>
							</Typography>

							<Grid
								container
								item
								sx={{
									border: "none",
									display: "flex",
									justifyContent: "center",
									width: "100%",
								}}
							>
								{isLoadingLogs ? (
									<CircularProgress />
								) : (
									<Grid item>
										<TableContainer
											component={Paper}
											variant="outlined"
											style={{
												marginTop: 10,
												marginRight: 1,
												marginBottom: 1,
												width: "100%",
												padding: 1,
											}}
											aria-label="customized table"
										>
											<Table aria-label="a dense table" className="" sx={{ overflow: "auto" }}>
												<TableRow>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Data Criada</TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Tipo Evento</TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Nome Tabela</TableCell>
													<TableCell
														sx={{
															color: "#080808",
															fontWeight: "bold",
															width: "18%",
														}}
													>
														{" "}
														Referência ID Análise
													</TableCell>
													<TableCell
														sx={{
															color: "#080808",
															fontWeight: "bold",
															width: "18%",
														}}
													>
														{" "}
														Usuário Id{" "}
													</TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Usuário Nome </TableCell>
													<TableCell sx={{ color: "#080808", fontWeight: "bold" }}> Payload </TableCell>
												</TableRow>
												<TableBody sx={{ textAlign: "center" }}>
													{!dataLogs?.length && (
														<TableRow key={0}>
															<TableCell
																colSpan={7}
																sx={{
																	fontWeight: "normal",
																	borderBottom: "none",
																	textAlign: "center",
																}}
															>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"} fontSize={20}>
																	Não há registros de logs desta análise
																</Typography>
															</TableCell>
														</TableRow>
													)}
													{dataLogs?.map((log: Logs, index: number) => (
														<TableRow key={index}>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography variant="subtitle2" color={"#080808"} fontWeight={"normal"}>
																	{moment(log?.createdDate, "YYYY-MM-DD").format("DD/MM/YYYY")}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.eventType}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.tableName}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.recordId}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.userId}
																</Typography>
															</TableCell>
															<TableCell sx={{ fontWeight: "normal" }}>
																<Typography color={"#080808"} variant="subtitle2" fontWeight={"normal"}>
																	{log?.userName}
																</Typography>
															</TableCell>
															<TableCell>
																<Button
																	color="info"
																	disableElevation
																	variant={"outlined"}
																	sx={{ alignItems: "flex-start" }}
																	startIcon={<RemoveRedEyeOutlinedIcon />}
																	onClick={() => handleGetLogsPayload(log?.payload, log?._id)}
																>
																	Payload
																</Button>
															</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								)}
							</Grid>
							<Typography
								sx={{
									mt: 5,
									textAlign: "end",
								}}
							>
								<Button
									style={{ backgroundColor: "red", color: "white" }}
									disableElevation
									variant={"contained"}
									startIcon={<CloseIcon />}
									onClick={handleCloseModalLogs}
								>
									{"Sair"}
								</Button>
							</Typography>
						</Box>,
					]}
				/>
			)}

			{isSuperAdmin && openModalDetailSerasaResponse && (
				<ModalDetailSerasaResponse
					data={rows}
					isClose={() => setOpenModalDetailSerasaResponse(false)}
					isOpenModal={openModalDetailSerasaResponse}
				/>
			)}

			{/* Modal Visualização do Payload Específico */}
			{loggedUser?.role == "admin" && (
				<ModalContext
					isCloseModal={handleCloseModalLogsPayload}
					isOpenModal={openModalLogsPayload}
					messageType={"info"}
					enableBackdropClose={false}
					overrideWidthValue="100"
					showButtonCancel={false}
					IMessage={[
						<Box style={{ width: "100%" }}>
							<Typography>
								<Typography
									sx={{
										color: "#000000",
										display: "inline-flex",
										fontSize: "20px",
										fontWeight: "bold",
									}}
								>
									<DescriptionOutlinedIcon
										sx={{
											fontSize: "35px",
											marginRight: "15px",
										}}
									/>
									Detalhe do Payload IdLog#{idLog}
								</Typography>
							</Typography>

							<Grid
								container
								item
								sx={{
									border: "none",
									display: "inline-flex",
								}}
							>
								<div>
									<pre>{JSON.stringify(payload, null, 1)}</pre>
								</div>
							</Grid>
							<Typography
								sx={{
									mt: 5,
									textAlign: "end",
								}}
							>
								<Button
									style={{ backgroundColor: "red", color: "white" }}
									disableElevation
									variant={"contained"}
									startIcon={<CloseIcon />}
									onClick={handleCloseModalLogsPayload}
								>
									{"Sair"}
								</Button>
							</Typography>
						</Box>,
					]}
				/>
			)}
		</LayoutBaseDePagina>
	);
};
