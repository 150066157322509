import {
	ArticleOutlined,
	AttachMoneyOutlined,
	Business,
	CalendarToday,
	CreditCard,
	DateRange,
	Email,
	Face4,
	Home,
	LocationOn,
	Money,
	Person,
	Phone,
	Portrait,
	Security,
	Work,
} from "@mui/icons-material";
import { Box, Card, CardContent, Chip, Divider, Grid, styled, Typography } from "@mui/material";
import { differenceInDays, differenceInYears } from "date-fns";
import { FC } from "react";
import InfoItemWithCopy from "../../../../../shared/components/InfoItemWithCopy/InfoItemWithCopy";
import {
	globalbackgroundProposal,
	globalColorTextProposal,
	globalFormatCurrencyBRL,
	globalFormatDates,
	globalMaskDocument,
	globalTranslate,
	globalTranslateFinally,
	globalTranslateMethodPayment,
	globalTranslateProfession,
	globalTranslateStatus,
	maskPhone,
} from "../../../../../shared/utils";

const StyledSection = styled(Box)(({ theme }) => ({
	marginBottom: theme.spacing(2),
	"&:last-child": {
		marginBottom: 0,
	},
}));

const SectionTitle = styled(Typography)({
	color: "#4c4c4d",
	fontWeight: 500,
	marginBottom: "12px",
});

interface FieldConfig {
	label: string;
	value: string | undefined;
	icon?: JSX.Element;
	image?: string;
	valueFormatter?: (value: string) => string;
	additionalInfo?: string;
	colSpan?: number;
	hidden?: boolean;
}

interface RowConfig {
	fields: FieldConfig[];
}

interface SectionConfig {
	title: string;
	rows: RowConfig[];
}

interface RenovateProposalDataProps {
	row: Record<string, any>;
}

const RenovateProposalData: FC<RenovateProposalDataProps> = ({ row }) => {
	const sections: SectionConfig[] = [
		{
			title: "Dados do Locatário",
			rows: [
				{
					fields: [
						{ label: "Nome", value: row.name, icon: <Person /> },
						{
							label: "CPF",
							value: row.document,
							icon: <Portrait />,
							valueFormatter: globalMaskDocument,
						},
					],
				},
				{
					fields: [
						{
							label: "Nascimento",
							value: row.birthDate?.substring(0, 10) + " 03:00:00",
							icon: <CalendarToday />,
							additionalInfo: row.birthDate
								? `(${differenceInYears(new Date(), new Date(row.birthDate?.substring(0, 10) + " 03:00:00"))} anos)`
								: "Não Informado",
							valueFormatter: globalFormatDates,
						},
						{ label: "Nome da Mãe", value: row.motherName, icon: <Face4 /> },
					],
				},
				{
					fields: [
						{
							label: "Vínculo Empregatício",
							value: row.employmentRelationShip,
							icon: <Work />,
							valueFormatter: globalTranslateProfession,
						},
					],
				},
			],
		},
		{
			title: "Contato",
			rows: [
				{
					fields: [
						{ label: "Email", value: row.email, icon: <Email /> },
						{
							label: "Telefone",
							value: row.phone,
							icon: <Phone />,
							valueFormatter: maskPhone,
						},
					],
				},
			],
		},
		{
			title: "Informações da Garantia",
			rows: [
				{
					fields: [
						{ label: "Tipo de Imóvel", value: row.propertyType, icon: <Home /> },
						{
							label: "Finalidade",
							value: row.propertyFinally,
							icon: <Home />,
							valueFormatter: (value: string) => globalTranslateFinally(value).toString(),
						},
					],
				},
				{
					fields: [
						{
							label: "Método de Pagamento",
							value: row.paymentMethod,
							icon: <CreditCard />,
							valueFormatter: (value: string) => globalTranslateMethodPayment(value).toString(),
						},
						{
							label: "Período da Garantia",
							value: `${globalFormatDates(new Date(row.termBegin?.substring(0, 10) + " 03:00:00").toISOString())} 
							a ${globalFormatDates(new Date(row.expiresAt?.substring(0, 10) + " 03:00:00").toISOString())}`,
							icon: <DateRange />,
						},
					],
				},
				{
					fields: [
						{
							label: "Endereço",
							value: `${row.addressStreet}, ${row.addressNumber} - ${row.addressComplement} - ${row.addressDistrict} - ${row.addressCity} - ${row.addressState} - ${row.addressZipcode}`,
							icon: <LocationOn />,
							colSpan: 12,
						},
						{ label: "Parceiro / Imobiliária", value: row.client?.name, icon: <Business />, colSpan: 8 },
						{
							label: "Cadastro via",
							value: globalTranslate(row?.createdFrom),
							image: row?.createdFrom === "web_service" ? "/4seg.png" : "/logogar.png",
							colSpan: 4,
						},
						{ label: "Certificado", value: row.certificateNumber, icon: <Security /> },
						{ label: "Plano", value: row.plan.description, icon: <ArticleOutlined />, additionalInfo: `(${row.fee} %)` },
						{
							label: "Mensalidade",
							value: row.assuranceMonthAmount,
							icon: <AttachMoneyOutlined />,
							valueFormatter: (value: string) => globalFormatCurrencyBRL(value).toString(),
						},
						{
							label: "LMI",
							value: row.lmiAmount,
							icon: <Money />,
							valueFormatter: (value: string) => globalFormatCurrencyBRL(value).toString(),
							additionalInfo: row.lmi ? `(${row.lmi}x)` : "",
						},
					],
				},
			],
		},
		{
			title: "Dados do Locador",
			rows: [
				{
					fields: [
						{ label: "Nome", value: row.lessorName, icon: <Person /> },
						{ label: "CPF", value: row.lessorCpf, icon: <Portrait />, valueFormatter: globalMaskDocument },
					],
				},
				{
					fields: [
						{
							label: "Nascimento",
							value: row.lessorBirthDate,
							icon: <CalendarToday />,
							additionalInfo: row.lessorBirthDate ? `(${differenceInYears(new Date(), new Date(row.lessorBirthDate))} anos)` : "Não informado",
							valueFormatter: globalFormatDates,
						},
						{
							label: "Telefone",
							value: row.lessorPhone,
							icon: <Phone />,
							valueFormatter: maskPhone,
						},
					],
				},
				{
					fields: [{ label: "Email", value: row.lessorEmail, icon: <Email /> }],
				},
			],
		},
	];

	return (
		<Card sx={{ margin: "auto", px: 4, borderRadius: 4 }}>
			<CardContent>
				{
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<Chip
							size="medium"
							label={` ${globalTranslateStatus(row.status)}`}
							sx={{
								color: globalColorTextProposal(
									row.status == "active" &&
										row.renovatedId &&
										differenceInDays(
											new Date(row.termBegin ? row.termBegin.substring(0, 10) + " 03:00:00" : "2021-01-01 03:00:01"),
											new Date().toISOString().substring(0, 10) + " 03:00:00"
										) > 0
										? "renovated"
										: row.status
								),
								fontWeight: "500",
								fontSize: "18px",
								background: globalbackgroundProposal(
									row.status == "active" &&
										row.renovatedId &&
										differenceInDays(
											new Date(row.termBegin ? row.termBegin.substring(0, 10) + " 03:00:00" : "2021-01-01 03:00:01"),
											new Date().toISOString().substring(0, 10) + " 03:00:00"
										) > 0
										? "renovated"
										: row.status
								),
								border: "0.5px solid #f1f1f1",
							}}
						/>
					</Box>
				}
				{sections.map((section, sectionIndex) => (
					<StyledSection key={sectionIndex}>
						<SectionTitle variant="h6">{section.title}</SectionTitle>
						{section.rows.map((row, rowIndex) => (
							<Grid container spacing={2} sx={{ mb: 2 }} key={rowIndex}>
								{row.fields
									.filter((field) => !field.hidden)
									.map((field, fieldIndex) => (
										<Grid item xs={field.colSpan || 12} sm={field.colSpan || 6} key={fieldIndex}>
											<InfoItemWithCopy
												label={field.label}
												value={field.valueFormatter ? field.valueFormatter(field.value || "") : field.value || ""}
												icon={field.icon}
												image={field.image}
												additionalInfo={field.additionalInfo}
											/>
										</Grid>
									))}
							</Grid>
						))}
						{sectionIndex < sections.length - 1 && <Divider sx={{ my: 3 }} />}
					</StyledSection>
				))}
			</CardContent>
		</Card>
	);
};

export default RenovateProposalData;
