import { Box, Button, Grid, Icon, LinearProgress, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { IVFormErros, useVForm, VForm, VTextField } from "../../../../../shared/forms";
import { useToast } from "../../../../../shared/hooks";

import { ICancelProposalPendency, ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";

interface ModalCancelProps {
	isClose: () => void;
	isOpenModal: boolean;
	rows: any;
}

export const ModalPendencyCancelProposal: React.FC<ModalCancelProps> = ({ isClose, isOpenModal, rows }) => {
	const toastHandler = useToast();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const { formRef } = useVForm();

	const formValidationSchemaCancelPendency: yup.SchemaOf<ICancelProposalPendency> = yup.object().shape({
		substatus: yup.string().required("Campo Obrigatório"),
		substatusreason: yup.string().required("Campo Obrigatório"),
	});

	const handleCancelProposalPendency = async (dados: ICancelProposalPendency) => {
		try {
			await formValidationSchemaCancelPendency.validate(dados, { abortEarly: false });

			setIsLoading(true);

			const result = await ProposalService.updatePendencyCancelId(rows.id, { ...dados });
			setIsLoading(false);

			if (result instanceof Error) {
				throw new Error(result.message);
			}

			toastHandler.present({
				type: "success",
				position: "top-right",
				messageOrContent: <ToastContent title={"Sucesso"} content={"Cancelamento efetivado com sucesso"} />,
			});
			navigate(`/garantias/`);
		} catch (error: any) {
			if (error instanceof yup.ValidationError) {
				const validationErrors: IVFormErros = {};
				error.inner.forEach((err) => {
					if (!err.path) return;
					validationErrors[err.path] = err.message;
				});
				formRef.current?.setErrors(validationErrors);
			} else {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={error.message} />,
				});
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{isOpenModal && (
				<ModalContext
					isCloseModal={isClose}
					isOpenModal={isOpenModal}
					messageType={"question"}
					showButtonCancel={false}
					textButtonConfirm={"Cancelar"}
					textButtonCancel={"Voltar"}
					ITitle={"Pendenciar o cancelamento ?"}
					IMessage={[
						<Box key="modal-cancel" style={{ width: "100%" }}>
							<Typography id="modal-modal-description" sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}>
								<VForm
									ref={formRef}
									onSubmit={handleCancelProposalPendency}
									onPointerEnterCapture={() => {}}
									onPointerLeaveCapture={() => {}}
									placeholder=""
								>
									<Grid container direction="column" padding={1} spacing={2}>
										{isLoading && (
											<Grid item>
												<LinearProgress variant="indeterminate" />
											</Grid>
										)}

										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12} md={4}>
												<VTextField
													fullWidth
													label="Status Cancelamento"
													name="substatus"
													disabled={isLoading}
													defaultValue="pending"
													select
												>
													<MenuItem value="pending">Pendente</MenuItem>
												</VTextField>
											</Grid>
										</Grid>
										<Grid container item direction="row" spacing={2}>
											<Grid item xs={12}>
												<VTextField
													fullWidth
													label="Descreva o motivo da pendencia"
													name="substatusreason"
													disabled={isLoading}
													multiline
													rows={4}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid container sx={{ display: "flex", justifyContent: "center", gap: 2, padding: 2 }}>
										<Button color="inherit" disableElevation variant={"contained"} startIcon={<Icon>arrow_back</Icon>} onClick={isClose}>
											{"Voltar"}
										</Button>
										<Button
											color="primary"
											disableElevation
											variant={"contained"}
											startIcon={<Icon>check</Icon>}
											onClick={() => formRef.current?.submitForm()}
										>
											{"Pendenciar"}
										</Button>
									</Grid>
								</VForm>
							</Typography>
						</Box>,
					]}
				/>
			)}
		</>
	);
};
