import {
	formatColorStatusAnalysis,
	globalBackground,
	globalBackgroundPayment,
	globalColorLine,
	globalColorText,
	globalColorTytle,
	globalTranslate,
	globalTranslateStatusAnalysis,
	translateBiometryStatus,
} from "../../../shared/utils";
import { Box, Button, Grid, Icon, Paper, Skeleton, Tooltip, Typography, useTheme } from "@mui/material";
import { useProfile } from "../../../context/ProfileContext";
import { format } from "date-fns";
import moment from "moment";
import { Transaction } from "../../../shared/types/TTransaction";
import { InstallmentSetup } from "../../../shared/types/TIntallmentSetup";

export type TPlanList = {
	description: string;

	commercialFee: string;
};

interface IDetailAnalysisProps {
	status?: string;
	showButtonStatus: boolean;
	onclickbuttonStatus?: () => void;
	onclickbuttonResend: (id: string, email: string) => void;
	textButtonStatus?: string;
	dataLoading: boolean;

	origin?: string;
	createAt?: string;
	email?: string;
	addressStreet?: string;
	addressNumber?: string;
	addressComplement?: string;
	addressDistrict?: string;
	addressCity?: string;
	addressState?: string;
	addressZip?: string;

	biometryStatus?: string;
	biometryUpdatedAt?: string;

	rentAmount?: string;
	condominiumAmount?: string;
	eletrictAmount?: string;
	waterAmount?: string;
	gasAmount?: string;
	IPTUAmount?: string;

	vigencyAmount?: string;
	clientName?: string;
	propertyType?: string;
	propertyOccupation?: string;

	expiresAt: string;
	termBegin: string;
	canceledReason: string;
	canceledType?: string;
	limitSerasa?: string;
	baseCalc?: string;

	planList?: any[];
	transaction?: Transaction;
	installmentSetup?: InstallmentSetup;
}

export const DetailAnalisys: React.FC<IDetailAnalysisProps> = ({
	status = "",
	onclickbuttonStatus,
	onclickbuttonResend,
	createAt = "",
	origin = "",
	addressStreet = "",
	addressNumber = "",
	addressComplement = "",
	addressDistrict = "",
	addressCity = "",
	addressState = "",
	email = "",
	biometryStatus = "",
	biometryUpdatedAt = "",
	addressZip = "",
	rentAmount = "",
	condominiumAmount = "",
	eletrictAmount = "",
	waterAmount = "",
	gasAmount = "",
	IPTUAmount = "",
	vigencyAmount = "",
	clientName = "",
	propertyType = "",
	propertyOccupation = "",
	expiresAt = "",
	termBegin = "",
	canceledReason = "",
	canceledType = "",
	limitSerasa = "",
	baseCalc = "aluguel",
	dataLoading,
	planList = [],
	transaction = {} as Transaction,
	installmentSetup = {} as InstallmentSetup,
}) => {
	const theme = useTheme();
	const { isSuperAdmin } = useProfile();

	//console.log('planlist...',planList);

	return (
		<Grid container component={Paper} sx={{ width: "100%", maxWidth: "1300px", padding: 2 }}>
			<Grid container item direction="row" spacing={1}>
				<Grid item xs={isSuperAdmin ? 7 : 8}>
					<Typography variant="h6" color={globalColorTytle}>
						Dados da Análise
					</Typography>
				</Grid>

				<Grid item xs={12} sm={12} md={5} display={"flex"} displayPrint={"flex"} justifyContent={"flex-end"} alignItems={"center"}>
					{!dataLoading && (
						<Box>
							<Typography
								variant="h5"
								color={formatColorStatusAnalysis(status ? status : "open")}
								sx={{
									background: globalBackground(status),
									paddingX: 2,
									paddingY: 0.5,
									borderRadius: 2,
								}}
							>
								{status ? globalTranslateStatusAnalysis(status) : ""}
							</Typography>
						</Box>
					)}
					{dataLoading && <Skeleton height={"60px"} width={"15rem"} />}
					{isSuperAdmin && (
						<Tooltip title="Alterar Status" placement="top-start">
							<Button sx={{ color: "#1c1b1b" }} disableElevation variant="text" onClick={onclickbuttonStatus}>
								<Icon sx={{ fontSize: "30px" }}>cached_Outlined_Icon</Icon>
							</Button>
						</Tooltip>
					)}
				</Grid>
			</Grid>

			{status == "pre_approved" && (
				<Grid container item direction="row" sx={{ padding: 1, background: globalColorLine(1), mt: 1 }}>
					<Grid
						item
						sx={{
							width: "100%",
							border: "1px solid #eac1c1",
							background: globalBackgroundPayment("overdue"),
							padding: "1.2% 2.3%",
							borderRadius: "4px",
						}}
					>
						<Box display={"flex"} gap={1} alignItems={"center"}>
							<Typography variant="body1" sx={{ color: "#d54949" }}>
								Analise Pré-Aprovada com valor de até
							</Typography>
							<Typography variant="body1" sx={{ color: "#d54949", fontWeight: "600", fontSize: "18px" }}>
								{parseFloat(limitSerasa).toLocaleString("pt-br", {
									style: "currency",
									currency: "BRL",
								})}
							</Typography>
						</Box>
						<Typography variant="body2" sx={{ color: "#d54949", fontWeight: "bold" }}>
							Próximos passos:
						</Typography>
						<Typography variant="body2" sx={{ color: "#d54949" }}>
							Você deverá ajustar o valor do aluguel negociando junto ao proprietário ou buscar outro imóvel que possua o valor do aluguel
							compatível com este valor pré-aprovado.
						</Typography>
						<Typography variant="body2" sx={{ color: "#d54949" }}>
							Será necessário clicar em REANALISE para ajustar o valor do aluguel (conforme o limite aprovado) e, caso tenha buscado outro imóvel,
							alterar os DADOS DO IMÓVEL. (Não é necessário realizar uma nova análise)
						</Typography>
					</Grid>
				</Grid>
			)}

			{!dataLoading && (
				<Grid container item direction="row" sx={{ padding: 0.71, background: globalColorLine(1) }}>
					<Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
						<Typography variant="caption" color={"text.secondary"}>
							Origem da análise
						</Typography>
						<Typography variant="subtitle2">{globalTranslate(origin)}</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							{"Criada "}
						</Typography>
						<Typography variant="subtitle2">{createAt ? moment(createAt).format("DD/MM/yyyy HH:mm:ss") : "Não informada"}</Typography>
					</Grid>
				</Grid>
			)}
			{dataLoading && <Skeleton height={"50px"} width={"70rem"} />}

			{!dataLoading && (
				<Grid container item direction="row" sx={{ padding: 0.71, background: globalColorLine(2) }}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography variant="caption" color={"text.secondary"}>
							Endereço
						</Typography>
						<Typography variant="subtitle2">
							{`${
								addressStreet != null && addressStreet != ""
									? `${
											addressComplement != null && addressComplement != ""
												? `${addressStreet},${addressNumber} - ${addressComplement} -
                                              ${addressDistrict} - ${addressCity} - ${addressState} - ${addressZip}`
												: `${addressStreet},${addressNumber} - 
                                              ${addressDistrict} - ${addressCity} - ${addressState} - ${addressZip}`
									  } `
									: ""
							}`}
						</Typography>
					</Grid>
				</Grid>
			)}
			{dataLoading && <Skeleton height={"50px"} width={"70rem"} />}

			{!dataLoading && (
				<Grid container item direction="row" sx={{ padding: 0.71, background: globalColorLine(3) }}>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Valor Aluguel
						</Typography>
						<Typography variant="subtitle2">
							{parseFloat(`${rentAmount}`).toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Valor Condominio
						</Typography>
						<Typography variant="subtitle2">
							{parseFloat(`${condominiumAmount}`).toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Valor Energia Elétrica
						</Typography>
						<Typography variant="subtitle2">
							{parseFloat(`${eletrictAmount}`).toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}
						</Typography>
					</Grid>
				</Grid>
			)}
			{dataLoading && <Skeleton height={"50px"} width={"70rem"} />}

			{!dataLoading && (
				<Grid container item direction="row" sx={{ padding: 0.71, background: globalColorLine(4) }}>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Valor Gas
						</Typography>
						<Typography variant="subtitle2">
							{parseFloat(`${gasAmount}`).toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Valor Agua
						</Typography>
						<Typography variant="subtitle2">
							{parseFloat(`${waterAmount}`).toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}
						</Typography>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
						<Typography variant="caption" color={"text.secondary"}>
							Valor IPTU
						</Typography>
						<Typography variant="subtitle2">
							{parseFloat(`${IPTUAmount}`).toLocaleString("pt-br", {
								style: "currency",
								currency: "BRL",
							})}
						</Typography>
					</Grid>
				</Grid>
			)}
			{dataLoading && <Skeleton height={"50px"} width={"70rem"} />}

			{!dataLoading && (
				<Grid container item direction="row" sx={{ padding: 0.71, background: globalColorLine(5) }}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container item direction="row">
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Parceiro
								</Typography>
								<Typography variant="subtitle2">{clientName}</Typography>
							</Grid>

							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Status Biometria
								</Typography>
								<Typography variant="subtitle2">{translateBiometryStatus(biometryStatus)}</Typography>
							</Grid>
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Biometria atualizado em
								</Typography>
								<Typography variant="subtitle2">{biometryUpdatedAt}</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
			{dataLoading && <Skeleton height={"50px"} width={"70rem"} />}

			{!dataLoading && (
				<Grid container item direction="row" sx={{ padding: 0.71, background: globalColorLine(6) }}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container item direction="row">
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Tipo de Imovel
								</Typography>
								<Typography variant="subtitle2">{propertyType}</Typography>
							</Grid>

							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Tipo de locação
								</Typography>
								<Typography variant="subtitle2">{globalTranslate(propertyOccupation || "")}</Typography>
							</Grid>

							<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Meses de Vigência
								</Typography>
								<Typography variant="subtitle2">{vigencyAmount}</Typography>
							</Grid>
							{status === "awaiting_signature" ? (
								<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
									<Typography variant="caption" color={"text.secondary"}>
										Período de Vigência
									</Typography>
									<Typography variant="subtitle2">
										{moment(termBegin).format("L")}
										{" - "}
										{moment(expiresAt).format("L")}
									</Typography>
								</Grid>
							) : (
								""
							)}
						</Grid>
					</Grid>
				</Grid>
			)}
			{dataLoading && <Skeleton height={"50px"} width={"70rem"} />}

			{!dataLoading && (
				<Grid container item direction="row" sx={{ padding: 0.71, background: globalColorLine(7) }}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container item direction="row">
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Planos Aprovados
								</Typography>
							</Grid>
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Taxa aprovada
								</Typography>
							</Grid>
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Valor parcela
								</Typography>
							</Grid>
						</Grid>
						<Grid container item direction="row">
							{(status === "issued" || status === "awaiting_signature" || status === "awaiting_biometria") &&
								planList.map((planResult: any, index) => {
									return propertyOccupation == "residential" ? (
										<Grid container item direction="row">
											<Grid item xs={4} sm={4} md={4}>
												<Typography>
													{
														//planResult.planId

														planResult.plan.description.toLowerCase() +
															(planResult?.planId.includes("__charges") ? " + encargos" : "")
													}
												</Typography>
											</Grid>
											<Grid item xs={4} sm={4} md={4}>
												<Typography>{planResult?.residentialFee}%</Typography>
											</Grid>
											<Grid item xs={4} sm={4} md={4}>
												<Typography>
													{planResult.planId.includes("__charges")
														? (
																(parseFloat(rentAmount) + parseFloat(condominiumAmount) + parseFloat(IPTUAmount)) *
																(planResult?.residentialFee / 100)
														  ).toLocaleString("pt-br", {
																style: "currency",
																currency: "BRL",
														  })
														: (parseFloat(rentAmount) * (planResult?.residentialFee / 100)).toLocaleString("pt-br", {
																style: "currency",
																currency: "BRL",
														  })}
												</Typography>
											</Grid>
										</Grid>
									) : (
										<Grid container item direction="row">
											<Grid item xs={4} sm={4} md={4}>
												<Typography>{planResult?.plan?.description}</Typography>
											</Grid>
											<Grid item xs={4} sm={4} md={4}>
												<Typography>{planResult?.commercialFee}%</Typography>
											</Grid>
											<Grid item xs={4} sm={4} md={4}>
												<Typography>
													{baseCalc == "encargos"
														? (
																(parseFloat(rentAmount) + parseFloat(condominiumAmount) + parseFloat(IPTUAmount)) *
																(planResult?.residentialFee / 100)
														  ).toLocaleString("pt-br", {
																style: "currency",
																currency: "BRL",
														  })
														: (parseFloat(rentAmount) * (planResult?.commercialFee / 100)).toLocaleString("pt-br", {
																style: "currency",
																currency: "BRL",
														  })}
												</Typography>
											</Grid>
										</Grid>
									);
								})}
						</Grid>
						{status !== "issued" && status !== "awaiting_signature" && status !== "awaiting_biometria" && status !== "pre_approved" && (
							<Typography variant="subtitle2">Nenhum plano aprovado</Typography>
						)}
					</Grid>
				</Grid>
			)}

			{dataLoading && <Skeleton height={"50px"} width={"70rem"} />}

			{!dataLoading && (
				<Grid container item direction="row" sx={{ padding: 0.71, background: globalColorLine(7) }}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container item direction="row">
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid>
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
								<Typography variant="caption" color={"text.secondary"}>
									Taxa de setup
								</Typography>
							</Grid>
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid>
							<Grid item xs={4} sm={4} md={4} lg={4} xl={4}></Grid>
						</Grid>
						<Grid container item direction="row">
							<Grid item xs={4} sm={4} md={4}></Grid>
							<Grid item xs={4} sm={4} md={4}>
								<Typography variant="subtitle2">
									{!!transaction?.rawAmount &&
										parseFloat(transaction?.rawAmount as string).toLocaleString("pt-br", {
											style: "currency",
											currency: "BRL",
										})}

									{!transaction?.rawAmount && "Não cobrada"}
								</Typography>
							</Grid>
							<Grid item xs={4} sm={4} md={4}>
								{transaction?.status === "pending" && (
									<Typography variant="subtitle2">
										<Button onClick={() => onclickbuttonResend(installmentSetup?.id as string, email as string)}>
											Reenviar link de pagamento
										</Button>
									</Typography>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}

			{!dataLoading && (
				<>
					{status === "canceled" && (
						<>
							<Grid container item direction="row" sx={{ padding: 0.71, background: globalColorLine(8) }}>
								<Grid item xs={12}>
									<Grid container item direction="row">
										<Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
											<Typography variant="caption" color={"text.secondary"}>
												Motivo do Cancelamento
											</Typography>
										</Grid>
									</Grid>
									<Grid container item direction="row">
										<Typography
											variant="subtitle1"
											sx={{
												background: canceledType != "null" && canceledType != null && canceledType ? globalBackground(status) : "",
												paddingX: 1.5,
												paddingY: 0.2,
												borderRadius: 2,
												color: canceledType != "null" && canceledType != null && canceledType ? globalColorText("canceled") : "",
											}}
										>
											{canceledType != "null" && canceledType != null && canceledType ? canceledType : ""}
										</Typography>
									</Grid>
								</Grid>
							</Grid>

							<Grid container item direction="row" sx={{ padding: 0.71, background: globalColorLine(9) }}>
								<Typography variant="caption" color={"text.secondary"}>
									Descrição do Motivo
								</Typography>
								<Grid container item direction="row">
									<Grid item xs={12}>
										<Typography variant="subtitle2">
											{canceledReason != "null" && canceledReason != null && canceledReason ? canceledReason : ""}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</>
					)}
				</>
			)}

			{dataLoading && <Skeleton height={"50px"} width={"70rem"} />}
		</Grid>
	);
};
