import * as yup from "yup";

export const formValidationSchema = yup.object().shape({
	email: yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
	termBegin: yup.date().typeError("Data inválida").required("Início da vigência é obrigatório"),
	contractBegin: yup.date().typeError("Data inválida").required("Início do contrato é obrigatório"),
	contractEnd: yup
		.date()
		.typeError("Data inválida")
		.min(yup.ref("contractBegin"), "Fim do contrato deve ser posterior ao início")
		.required("Fim do contrato é obrigatório"),
	rentAmount: yup.string().required("Valor do aluguel é obrigatório"),
	condominiumAmount: yup
		.string()

		.required("Valor do condomínio é obrigatório"),
	electricEnergyAmount: yup
		.string()

		.required("Valor da energia é obrigatório"),
	waterAmount: yup
		.string()

		.required("Valor da água é obrigatório"),
	gasAmount: yup
		.string()

		.required("Valor do gás é obrigatório"),
	iptuAmount: yup
		.string()

		.required("Valor do IPTU é obrigatório"),
	assuranceMonthAmount: yup
		.string()

		.when("planId", {
			is: (val: string) => !!val,
			then: (schema) => schema.required("Valor do seguro é obrigatório"),
			otherwise: (schema) => schema,
		}),
	lmi: yup.string().when("planId", {
		is: (val: string) => !!val,
		then: (schema) => schema.required("LMI é obrigatório"),
		otherwise: (schema) => schema,
	}),
	lmiAmount: yup
		.string()

		.when("planId", {
			is: (val: string) => !!val,
			then: (schema) => schema.required("Valor do LMI é obrigatório"),
			otherwise: (schema) => schema,
		}),
	planId: yup.string().trim().default(""),
	fee: yup
		.string()

		.when("planId", {
			is: (val: string) => !!val,
			then: (schema) => schema.required("Taxa é obrigatória"),
			otherwise: (schema) => schema,
		}),
	rentMonthsCount: yup.string().matches(/^\d+$/, "Quantidade de meses deve ser um número inteiro").required("Quantidade de meses é obrigatória"),
	paymentMethod: yup.string().required("Forma de pagamento é obrigatória"),
	expiresAt: yup.date().typeError("Data inválida").required("Data de expiração é obrigatória"),
	minResidential: yup
		.string()

		.notRequired(),
	maxResidential: yup
		.string()

		.notRequired(),
	minCommercial: yup
		.string()

		.notRequired(),
	maxCommercial: yup
		.string()

		.notRequired(),
	rate: yup
		.string()

		.notRequired(),
	propertyFinally: yup.string().when("planId", {
		is: (val: string) => !!val,
		then: (schema) => schema.required("Campo obrigatório"),
		otherwise: (schema) => schema,
	}),
});
