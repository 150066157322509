import { Box, Button, Card, CardContent, Chip, Typography } from "@mui/material";
import React from "react";
import { globalMaskReal } from "../../../../../shared/utils";

export interface Plan {
	id: number | string;
	name: string;
	price: number;
	description: string;
	lmiValue: number;
	lmi?: number;
	commission?: number;
	taxa?: number;
	flagRateCharge?: string;
}

interface PlanCardProps {
	plan: Plan;
	isSelected: boolean;
	onSelect: (plan: Plan) => void;
	onClick: () => void;
	currentPlanId: string;
}

const PlanCard: React.FC<PlanCardProps> = ({ plan, isSelected, onSelect, onClick, currentPlanId }) => {
	return (
		<Card
			sx={{
				borderRadius: "24px",
				boxShadow: isSelected ? "0 8px 24px rgba(130, 87, 230, 0.25)" : "0 4px 16px rgba(0, 0, 0, 0.1)",
				border: isSelected ? "2px solid #8257e6" : "none",
				background: "#ffffff",
				transition: "all 0.3s ease",
				transform: isSelected ? "scale(1.02)" : "scale(1)",
				overflow: "visible",
				position: "relative",
				"&:hover": {
					transform: "translateY(-4px)",
					boxShadow: "0 12px 32px rgba(0, 0, 0, 0.12)",
				},
			}}
			onClick={onClick}
		>
			<CardContent sx={{ padding: 3 }}>
				{plan.id === currentPlanId && (
					<Chip
						//icon={<StarIcon />}
						label="Plano Atual"
						color="secondary"
						sx={{
							position: "absolute",
							top: -12,
							right: 24,
							backgroundColor: "#8257e6",
							"& .MuiChip-label": {
								color: "#ffffff",
								fontWeight: "400",
							},
							"& .MuiChip-icon": {
								color: "#ffffff",
							},
						}}
					/>
				)}

				<Typography
					variant="h5"
					sx={{
						fontWeight: "400",
						textAlign: "center",
						color: "#1A1A1A",
						letterSpacing: "-0.01em",
					}}
				>
					{plan.name}
				</Typography>
				<Typography sx={{ textAlign: "center", fontWeight: 500 }}>{plan.flagRateCharge === "1" ? `(Aluguel + Encargos)` : ""}</Typography>
				<Box sx={{ textAlign: "center", mb: 3 }}>
					<Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-end" }}>
						<Typography>R$ </Typography>
						<Typography
							variant="h3"
							component="div"
							sx={{
								color: "primary.main",
								fontSize: "2.25rem",
								lineHeight: 1.2,
								letterSpacing: "-0.02em",
							}}
						>
							{globalMaskReal(plan.price.toFixed(2))}
						</Typography>
					</Box>
					<Typography
						sx={{
							color: "#666666",
							fontFamily: "'Inter', sans-serif",
							fontWeight: "400",
						}}
					>
						/mês
					</Typography>
				</Box>

				<Box sx={{ mb: 3 }}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							mb: 1.5,
							py: 1,
							borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
						}}
					>
						<Typography sx={{ color: "#666666", fontWeight: "400" }}>Taxa Garanti</Typography>
						<Typography sx={{ color: "#1A1A1A", fontWeight: "400" }}>{plan.taxa?.toFixed(2)}%</Typography>
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							mb: 1.5,
							py: 1,
							borderBottom: "1px solid rgba(0, 0, 0, 0.06)",
						}}
					>
						<Typography sx={{ color: "#666666", fontWeight: "400" }}>LMI</Typography>
						<Typography sx={{ color: "#1A1A1A", fontWeight: "400" }}>{plan.lmi}x</Typography>
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							py: 1,
						}}
					>
						<Typography sx={{ color: "#666666", fontWeight: "400" }}>Valor LMI</Typography>
						<Typography sx={{ color: "#1A1A1A", fontWeight: "400" }}>R$ {globalMaskReal(plan.lmiValue.toFixed(2))}</Typography>
					</Box>
				</Box>

				<Button
					variant="contained"
					fullWidth
					onClick={(e) => {
						e.stopPropagation();
						onSelect(plan);
					}}
					sx={{
						mt: 1,
						py: 1.5,
						backgroundColor: "#8257e6",
						borderRadius: "8px",
						textTransform: "none",
						fontSize: "1rem",
						fontWeight: "400",
						fontFamily: "'Inter', sans-serif",
						boxShadow: "none",
						letterSpacing: "0.01em",
						"&:hover": {
							backgroundColor: "#9466ff",
							boxShadow: "0 4px 12px rgba(130, 87, 230, 0.25)",
						},
					}}
				>
					Contratar
				</Button>
			</CardContent>
		</Card>
	);
};

export default PlanCard;
