import { globalRemoveMaskReal } from "../../../../../shared/utils";

export const calculateExpirationDate2 = (startDate: string, months: number): string => {
	const date = new Date(startDate);

	if (isNaN(date.getTime())) return "";

	date.setMonth(date.getMonth() + months);
	date.setDate(date.getDate() - 1);

	return date.toISOString().split("T")[0];
};

export const normalizeMonetaryValue = (value: string | number): number => {
	if (typeof value === "string") {
		return parseFloat(globalRemoveMaskReal(value));
	}
	return parseFloat(value.toString());
};

// helpers/dateCalculations.ts
import { addDays, addMonths, format, parseISO } from "date-fns";

/**
 * Calcula o novo `termBegin`, `expiresAt` e `contractEnd` usando `date-fns`.
 * @param currentExpiresAt - Data de expiração original (vindo de `row`).
 * @param rentMonthsCount - Quantidade de meses da vigência.
 * @returns Um objeto contendo `termBegin`, `expiresAt` e `contractEnd`.
 */
export const calculateDatesDateFns = (currentExpiresAt: string, rentMonthsCount: string): { termBegin: string; expiresAt: string; contractEnd: string } => {
	if (!currentExpiresAt || !rentMonthsCount) {
		console.error("Dados insuficientes para cálculo das datas.");
		return { termBegin: "", expiresAt: "", contractEnd: "" };
	}

	// Novo `termBegin`: 1 dia após o `currentExpiresAt`
	const newTermBegin = addDays(parseISO(currentExpiresAt), 1);

	// Novo `expiresAt`: `rentMonthsCount` meses após o novo `termBegin` - 1 dia
	const newExpiresAt = addDays(addMonths(newTermBegin, Number(rentMonthsCount)), -1);

	return {
		termBegin: format(newTermBegin, "yyyy-MM-dd"),
		expiresAt: format(newExpiresAt, "yyyy-MM-dd"),
		contractEnd: format(newExpiresAt, "yyyy-MM-dd"), // Fim do contrato será igual ao `expiresAt`
	};
};

export const validateRentAmount = (
	rentAmount: string,
	propertyFinally: string,
	limits: {
		maxCommercial: string;
		maxResidential: string;
		minCommercial: string;
		minResidential: string;
		rate: string; // Índice percentual
		initialRentAmount: string; // Valor original de `rentAmount` vindo de `row`
	}
): string => {
	const amount = parseFloat(rentAmount) || 0;
	const initialAmount = parseFloat(limits.initialRentAmount) || 0;
	const rate = parseFloat(limits.rate) || 0;

	if (propertyFinally === "commercial") {
		const min = parseFloat(limits.minCommercial) || 0;
		const max = parseFloat(limits.maxCommercial) || Infinity;
		// Determina o limite ajustado
		const adjustedMax = initialAmount > max ? initialAmount + initialAmount * (rate / 100) : max;

		if (amount < min) return min.toFixed(2); // Abaixo do mínimo
		if (amount > adjustedMax) return adjustedMax.toFixed(2); // Acima do limite ajustado
		return rentAmount; // Valor dentro do intervalo permitido
	}

	if (propertyFinally === "residential") {
		const min = parseFloat(limits.minResidential) || 0;
		const max = parseFloat(limits.maxResidential) || Infinity;

		// Determina o limite ajustado
		const adjustedMax = initialAmount > max ? initialAmount + initialAmount * (rate / 100) : max;

		if (amount < min) return min.toFixed(2); // Abaixo do mínimo
		if (amount > adjustedMax) return adjustedMax.toFixed(2); // Acima do limite ajustado
		return rentAmount; // Valor dentro do intervalo permitido
	}

	return rentAmount; // Caso nenhuma regra se aplique
};
