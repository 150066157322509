"use client";

import { Close, Person, Settings } from "@mui/icons-material";
import { Box, IconButton, Modal, Paper, Typography, useTheme } from "@mui/material";

interface TimelineItem {
	type: "user" | "system";
	title: string;
	description: string;
}

interface VerticalTimelineProps {
	modalTitle: string;
	modalSubtitle: string;
	items: TimelineItem[];
	onClose: () => void;
}

function VerticalTimeline({ modalTitle, modalSubtitle, items, onClose }: VerticalTimelineProps) {
	const theme = useTheme();

	return (
		<Paper
			elevation={0}
			sx={{
				width: "100%",
				height: "100%",
				bgcolor: "common.white",
				position: "relative",
				display: "flex",
				flexDirection: "column",
				p: 2,
			}}
		>
			<IconButton
				onClick={onClose}
				sx={{
					position: "absolute",
					right: 40,
					top: 8,
					color: "text.secondary",
				}}
			>
				<Close />
			</IconButton>
			<Typography
				variant="h4"
				component="h1"
				gutterBottom
				sx={{
					fontWeight: 300,
					mb: 4,
					color: "primary.main",
					textAlign: "center",
				}}
			>
				{modalTitle}
				<Typography
					variant="h6"
					component="div"
					sx={{
						textTransform: "uppercase",
						letterSpacing: 3,
						color: "text.secondary",
					}}
				>
					{modalSubtitle}
				</Typography>
			</Typography>

			<Box
				sx={{
					flexGrow: 1,
					overflow: "auto",
					p: 4,
					position: "relative",
					"&::before": {
						content: '""',
						position: "absolute",
						left: "50%",
						width: 2,
						height: "100%",
						background: theme.palette.grey[300],
					},
				}}
			>
				{items.map((item, index) => (
					<Box
						key={index}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							position: "relative",
							mb: 4,
						}}
					>
						<Box
							sx={{
								width: 60,
								height: 60,
								borderRadius: "50%",
								backgroundColor: item.type === "user" ? "#e5dcfc" : "#E9F8F5",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								boxShadow: 20,
								zIndex: 1,
								border: "none",
							}}
						>
							{item.type === "user" ? <Person sx={{ fontSize: 24, color: "#6447aa" }} /> : <Settings sx={{ fontSize: 24, color: "#51b196" }} />}
						</Box>
						<Box
							sx={{
								position: "absolute",
								left: index % 2 === 0 ? "calc(50% + 40px)" : "auto",
								right: index % 2 === 1 ? "calc(50% + 40px)" : "auto",
								width: "calc(50% - 60px)",
								textAlign: index % 2 === 0 ? "left" : "right",
							}}
						>
							<Typography
								variant="h6"
								sx={{
									color: item.type === "user" ? "primary.main" : "secondary.main",
									fontWeight: "bold",
									mb: 1,
								}}
							>
								{item.title}
							</Typography>
							<Typography variant="body2" sx={{ color: "text.secondary" }}>
								{item.description}
							</Typography>
						</Box>
					</Box>
				))}
			</Box>
		</Paper>
	);
}

interface VerticalTimelineWrapperProps {
	modalTitle: string;
	modalSubtitle: string;
	items: TimelineItem[];
	openModal: boolean;
	onClose: () => void;
}

export default function VerticalTimelineWrapper({ modalTitle, modalSubtitle, items, openModal, onClose }: VerticalTimelineWrapperProps) {
	return (
		<Modal open={openModal} onClose={onClose} aria-labelledby="vertical-timeline" aria-describedby="vertical-timeline-description">
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "90vw",
					height: "90vh",
					maxWidth: 800,
					maxHeight: 800,
					bgcolor: "common.white",
					boxShadow: 24,
					borderRadius: 2,
					overflow: "hidden",
					display: "flex",
					flexDirection: "column",
					p: 2,
				}}
			>
				<VerticalTimeline modalTitle={modalTitle} modalSubtitle={modalSubtitle} items={items} onClose={onClose} />
			</Box>
		</Modal>
	);
}
