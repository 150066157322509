import { ContentCopy } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography, styled } from "@mui/material";
import React, { FC, useState } from "react";

const InfoItem = styled(Box)(({ theme }) => ({
	display: "flex",
	alignItems: "center", // Alterado de 'flex-start' para 'center'
	gap: theme.spacing(2),
	marginBottom: theme.spacing(1.5),
	"&:last-child": {
		marginBottom: 0,
	},
}));

const IconWrapper = styled(Box)(({ theme }) => ({
	backgroundColor: "#f2f6f9",
	borderRadius: "50%",
	width: 40,
	height: 40,
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	overflow: "hidden",
	"& > svg": {
		color: theme.palette.primary.main,
	},
}));

const StyledTypography = styled(Typography)({
	color: "#7f7f7f",
});

interface InfoItemWithCopyProps {
	label: string;
	value: string;
	icon?: React.ReactNode;
	image?: string;
	additionalInfo?: string;
	valueFormatter?: (value: string) => string;
}

const InfoItemWithCopy: FC<InfoItemWithCopyProps> = ({ label, value, icon, image, additionalInfo, valueFormatter }) => {
	const [showCopy, setShowCopy] = useState(false);

	const formattedValue = valueFormatter ? valueFormatter(value) : value;

	const handleCopy = () => {
		navigator.clipboard.writeText(formattedValue);
	};

	return (
		<InfoItem onMouseEnter={() => setShowCopy(true)} onMouseLeave={() => setShowCopy(false)}>
			{image ? <img src={image} alt={label} width={40} height={30} /> : <IconWrapper>{icon}</IconWrapper>}
			<Box sx={{ flex: 1 }}>
				<StyledTypography variant="body2" sx={{ opacity: 0.7 }}>
					{label}
				</StyledTypography>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<StyledTypography variant="body1">
						{formattedValue}
						{additionalInfo && (
							<StyledTypography variantMapping={{ body2: "span" }} variant="body2" sx={{ opacity: 0.7, ml: 1 }}>
								{additionalInfo}
							</StyledTypography>
						)}
					</StyledTypography>
					{showCopy && (
						<Tooltip title="Copiar">
							<IconButton
								size="small"
								onClick={handleCopy}
								sx={{
									ml: 1,
									width: 24,
									height: 24,
									"& .MuiSvgIcon-root": {
										fontSize: 16,
									},
								}}
							>
								<ContentCopy />
							</IconButton>
						</Tooltip>
					)}
				</Box>
			</Box>
		</InfoItem>
	);
};

export default InfoItemWithCopy;
