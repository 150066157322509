import { Box, Button, Icon, Paper, Skeleton, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";

interface IToolAnalysisProps {
	showButtonBack?: boolean;
	showButtonRenovate?: boolean;
	showButtonHelp?: boolean;

	showButtonBackLoading?: boolean;
	showButtonRenovateLoading?: boolean;
	showButtonHelpLoading?: boolean;

	clickBack?: () => void;
	clickRenovate?: () => void;
	clickHelp?: () => void;
}

export const ToolbarRenovate: React.FC<IToolAnalysisProps> = ({
	showButtonBack = true,
	showButtonRenovate = true,
	showButtonHelp = true,

	showButtonBackLoading = false,
	showButtonRenovateLoading = false,
	showButtonHelpLoading = false,

	clickBack,
	clickRenovate,
	clickHelp,
}) => {
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const mdDown = useMediaQuery(theme.breakpoints.down("md"));

	return (
		<Box gap={1} marginX={1} padding={1} paddingX={2} display="flex" alignItems="center" height={theme.spacing(5)} component={Paper}>
			{showButtonBack && !showButtonBackLoading && (
				<Button color="primary" disableElevation variant="outlined" onClick={clickBack} startIcon={<Icon>keyboard_double_arrow_left_icon</Icon>}>
					<Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
						Voltar
					</Typography>
				</Button>
			)}

			{showButtonRenovate && !showButtonRenovateLoading && (
				<Button
					color="primary"
					disableElevation
					variant="contained"
					onClick={clickRenovate}
					startIcon={<Icon>published_with_changes_Outlined_Icon</Icon>}
				>
					<Typography variant="button" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
						Concluir Renovação
					</Typography>
				</Button>
			)}

			{showButtonHelp && !showButtonHelpLoading && (
				<Box flex={1} display="flex" justifyContent="flex-end">
					<Button color="primary" disableElevation variant="text" onClick={clickHelp} startIcon={<Icon>help_outline</Icon>}>
						<Typography variant="caption" whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
							Ajuda
						</Typography>
					</Button>
				</Box>
			)}
		</Box>
	);
};
