import UploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

interface ModalAttachmentRenovateProps {
	isOpen: boolean;
	onClose: () => void;
	onAttach: (file: File) => void;
	onRemove: () => void;
	existingFileName?: string | null;
}

const ModalAttachmentRenovate: React.FC<ModalAttachmentRenovateProps> = ({ isOpen, onClose, onAttach, onRemove, existingFileName }) => {
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [error, setError] = useState<string | null>(null);	

	const supportedFormats = ["png", "jpg", "jpeg", "doc", "docx", "pdf", "odt"];

	useEffect(() => {
		if (existingFileName && isOpen) {
			setSelectedFile(new File([], existingFileName));
		} else if (!isOpen) {
			setSelectedFile(null);
			setError(null); // Limpa o erro ao fechar o modal
		}
	}, [existingFileName, isOpen]);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			const fileExtension = file.name.split(".").pop()?.toLowerCase();

			if (!fileExtension || !supportedFormats.includes(fileExtension)) {
				setError(`Formato inválido. Formatos permitidos: ${supportedFormats.join(", ").toUpperCase()}`);
				setSelectedFile(null);
			} else {
				setSelectedFile(file);
				setError(null); // Limpa o erro se o arquivo for válido
			}
		}
	};

	const handleAttach = () => {
		if (selectedFile) {
			onAttach(selectedFile);
			onClose();
		}
	};

	const handleRemoveFile = () => {
		setSelectedFile(null);
		onRemove();
	};

	const formatFileSize = (size: number) => {
		return size < 1024 ? `${size}B` : size < 1024 * 1024 ? `${(size / 1024).toFixed(2)}KB` : `${(size / (1024 * 1024)).toFixed(2)}MB`;
	};

	return (
		<Modal open={isOpen} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: 400,
					bgcolor: "background.paper",
					boxShadow: 24,
					p: 4,
					borderRadius: 2,
					textAlign: "center",
				}}
			>
				<Typography variant="h6" sx={{ fontWeight: "400" }}>
					Anexar Termo de Aditivo de Contrato
				</Typography>

				{error && (
					<Typography
						variant="body2"
						sx={{
							color: "#e36b5b",
							bgcolor: "#fcedea",
							py: 1,
							px: 2,
							borderRadius: 2,
							fontWeight: 500,
							textAlign: "center",
							mb: 2,
						}}
					>
						{error}
					</Typography>
				)}

				{selectedFile ? (
					<Box
						sx={{
							border: "1px dashed #0000FF",
							borderRadius: 2,
							p: 2,
							mt: 2,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<Typography variant="body1">{selectedFile.name}</Typography>
						<Typography variant="body2" sx={{ color: "#666", mb: 1 }}>
							Tamanho: {selectedFile.size > 0 ? formatFileSize(selectedFile.size) : "Desconhecido"}
						</Typography>
						<Button variant="text" onClick={handleRemoveFile} color="error" startIcon={<DeleteIcon />}>
							Apagar
						</Button>
					</Box>
				) : (
					<Box
						sx={{
							border: "2px dashed #FFD700",
							borderRadius: 2,
							p: 4,
							mt: 2,
							cursor: "pointer",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							justifyContent: "center",
						}}
						onClick={() => document.getElementById("upload-input")?.click()}
					>
						<UploadIcon sx={{ fontSize: 48, color: "#666", mb: 1 }} />
						<Typography variant="body2" sx={{ color: "#666" }}>
							Clique para realizar o carregamento do arquivo
						</Typography>
						<Typography variant="body2" sx={{ color: "#666" }}>
							Tamanho máximo: 50.00MB
						</Typography>
						<Typography variant="body2" sx={{ color: "#666", mt: 1 }}>
							Tipos suportados: PNG, JPG, JPEG, DOC, DOCX, PDF, ODT
						</Typography>
					</Box>
				)}

				<input id="upload-input" type="file" style={{ display: "none" }} onChange={handleFileChange} />

				<Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
					<Button variant="outlined" onClick={onClose}>
						Cancelar
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleAttach}
						disabled={!selectedFile || !!error} // Desabilitado se houver erro
					>
						Anexar
					</Button>
				</Box>
			</Box>
		</Modal>
	);
};

export default ModalAttachmentRenovate;
