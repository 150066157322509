import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContent, ToolbarRenovate } from "../../../../shared/components";
import LoadingDialog from "../../../../shared/components/LoadingModalDialog/LoadingModalDialog";
import { useToast } from "../../../../shared/hooks";
import { LayoutBaseDePagina } from "../../../../shared/layouts";
import { IProposalList, ProposalService } from "../../../../shared/services/api/proposal/ProposalService";
import { globalMaskDocument } from "../../../../shared/utils";
import RenovateProposalData from "./components/renovateProposalData";
import RenovateProposalForm from "./components/renovateProposalForm";
import VerticalTimelineWrapper from "./components/VerticalTimelineWrapper";

export const ProposalRenovatePresentation: React.FC = () => {
	const { id = "novo" } = useParams<"id">();
	const navigate = useNavigate();
	const toastHandler = useToast();
	const [isLoading, setIsLoading] = useState(false);
	const [rows, setRows] = useState<IProposalList | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const timelineItems: { type: "system" | "user"; title: string; description: string; date: string }[] = [
		{
			type: "user",
			title: "Início da Renovação",
			description: "O usuário iniciou o processo de renovação ao acessar a funcionalidade.",
			date: "2023-01-01",
		},
		{
			type: "system",
			title: "Cálculo de Datas de Vigência",
			description: "O sistema calculou as datas de início e fim da nova vigência com base na garantia anterior.",
			date: "2023-01-02",
		},
		{
			type: "system",
			title: "Carregamento de Dados no Formulário",
			description: "O sistema carregou os dados da garantia anterior no formulário para revisão.",
			date: "2023-01-03",
		},
		{
			type: "user",
			title: "Revisão e Interação no Formulário",
			description: "O usuário revisou os dados carregados, inseriu informações adicionais e ajustou os valores.",
			date: "2023-01-04",
		},
		{
			type: "system",
			title: "Validação de Regras de Limites",
			description: "O sistema validou os valores inseridos pelo usuário, ajustando-os conforme as regras de limite.",
			date: "2023-01-05",
		},
		{
			type: "user",
			title: "Anexo de Termo Aditivo",
			description: "O usuário anexou o termo aditivo de contrato para contratos com vigência superior a 12 meses.",
			date: "2023-01-06",
		},
		{
			type: "user",
			title: "Seleção de Plano",
			description: "O usuário escolheu o plano de renovação desejado entre as opções disponíveis.",
			date: "2023-01-07",
		},
		{
			type: "system",
			title: "Envio de Dados para o Sistema",
			description: "O sistema recebeu os dados preenchidos pelo usuário e iniciou o processamento.",
			date: "2023-01-08",
		},
		{
			type: "system",
			title: "Processamento e Atualização do Contrato",
			description: "O sistema concluiu o processamento dos dados e atualizou o contrato com sucesso.",
			date: "2023-01-09",
		},
		{
			type: "system",
			title: "Confirmação da Renovação",
			description: "O sistema notificou o usuário sobre a conclusão bem-sucedida da renovação.",
			date: "2023-01-10",
		},
	];

	useEffect(() => {
		setIsLoading(true);
		ProposalService.getRenovateById(`${id}`).then((result) => {
			setIsLoading(false);
			if (result instanceof Error) {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={"Erro ao tentar abrir a garantia"} />,
				});
				navigate("/proposal");
			} else {
				try {
					const renovatedAtDate = result.renovateAt ? new Date(result.renovateAt) : null;
					const currentDate = new Date();
					const formatDate = (date: Date) => date.toISOString().split("T")[0];

					if (renovatedAtDate && !isNaN(renovatedAtDate.getTime())) {
						// Calcula a diferença em meses
						const diffMonths =
							(currentDate.getFullYear() - renovatedAtDate.getFullYear()) * 12 + (currentDate.getMonth() - renovatedAtDate.getMonth());

						if (diffMonths > 10) {
							// Exibe os dados apenas se estiverem dentro do prazo
							setRows(result);
						} else {
							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={"Atenção"} content={`Garantia fora do prazo para renovação ou já renovada .`} />,
							});
							navigate("/dashboard");
						}
					} else {
						// Caso a data seja inválida ou não exista, permite exibir os dados
						setRows(result);
					}
				} catch (error) {
					console.error("Erro ao processar os dados:", error);
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={"Erro"} content={"Erro ao processar os dados da renovação."} />,
					});
					navigate("/dashboard");
				}
			}
		});
	}, []);

	if (isLoading && !rows) {
		// Se quiser exibir algo enquanto carrega
		return (
			<LayoutBaseDePagina titulo="Garantias" subtitulo="Carregando...">
				<Box display="flex" justifyContent="center" mt={5}>
					{isLoading && <LoadingDialog isLoading={isLoading} loadingText={`Carregando dados da Garantia...`} />}
				</Box>
			</LayoutBaseDePagina>
		);
	}

	if (!rows) {
		return <></>;
	}

	return (
		<LayoutBaseDePagina
			titulo="Garantias"
			subtitulo={`Renovação de Garantia [ ${rows.name} - ${globalMaskDocument(rows.document)} ]`}
			barraDeFerramentas={
				<ToolbarRenovate
					clickBack={() => navigate("/garantias")}
					clickRenovate={() => {
						console.log("Renovar");
					}}
					showButtonRenovate={false} // Controlado pelo estado
					showButtonHelp={true}
					clickHelp={handleOpenModal}
				/>
			}
		>
			<Grid container spacing={2} sx={{ width: "99%", maxWidth: "1920px", mt: 1, ml: 1, mb: 2, gap: 2 }}>
				<Box sx={{ flexGrow: 3, flexBasis: 0, minWidth: 0, width: "100%" }}>
					<RenovateProposalData row={rows} />
				</Box>

				<Box sx={{ flexGrow: 2, flexBasis: 0, minWidth: 0, width: "100%" }}>
					<RenovateProposalForm row={rows} onSubmit={() => console.log("salvar")} />
				</Box>
			</Grid>

			<VerticalTimelineWrapper
				items={timelineItems}
				modalTitle="Fluxo de renovação"
				modalSubtitle="Detalhes do fluxo de renovação"
				openModal={isModalOpen}
				onClose={handleCloseModal}
			/>
		</LayoutBaseDePagina>
	);
};
